// import axios from "axios";

const KAMPUSURL = "https://demoapi.ueducate.in";
// const KAMPUSURL = 'http://192.168.3.45:2505/v1';

// const KATBOOKURLV1 = `http://192.168.2.30:2303/v1`;
// const KATBOOKURLV2 = `http://192.168.2.30:2303/v2`;

// const KATBOOKURLV1 = `https://s3api.katbook.com/v1`;
// const KATBOOKURLV2 = `https://s3api.katbook.com/v2`;

// const KAMPUSURLAPI = `http://192.168.2.33:2506/v1`;
const KAMPUSURLAPI = `https://iskconsamskritiapi.kampus.org.in/v1`;
// const KAMPUSURLAPI = `http://192.168.2.33:2506/v1`;
// const KAMPUSURLAPI = `https://iskconsamskritiapitest.kampus.org.in/v1`;

// const KATBOOKURLV1 = `http://192.168.3.45:2303/v1`;
const KATBOOKURLV1 = `https://api1.katbook.com/v1`;
// const KATBOOKURLV2 = `https://api1.katbook.com/v2`;

export async function KampusLogin(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURL}/login/checklogin`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getDynamicTimetable(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURL}/timeTableNew/getDynamicTimeTableBasedOnLessonPlan`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getDynamicTimetableByStaff(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURL}/timeTableNew/getDynamicTimeTableForStaff`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getDynamicSubjects(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURL}/collabration/dynamicFetchSubjects`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {}
}

export async function getAllKatBookSubjects(body) {
  const response = await fetch(`${KATBOOKURLV1}/kampus/get/book1`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllKatBookSubjectsCorporate(body) {
  const response = await fetch(`${KATBOOKURLV1}/kampus/get/book5`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllKatBookSubjectsCount(body) {
  const response = await fetch(`${KATBOOKURLV1}/book/unitsandsessioncount1`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getUnitSessionNameFromtable(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KATBOOKURLV1}/variables/subject/unitandsession`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function kampusJoinMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/join`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusStartMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/start`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusInfoMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/info`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusStopMeeting(params1, body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/stop`, {
    method: `PUT`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

////////// start student and teacher fetching subjects  /////////

export async function getTeacherSubjects(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURL}/studentdetails/classDetailsAndSubjectDetailsForStaff`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

/////// end student and teacher fetching subjects //////////

///  assignment  /////////

////////// Start Question Type //////////

export async function createQuestionType(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/questiontype/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export const getQuestionTypeByInst = async () => {
  // const token = localStorage.getItem(`token`);
  let response;
  try {
    response = await fetch(`${KAMPUSURLAPI}/questiontype/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};

export async function updateQuestionType(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/questiontype/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

////////// End Question Type //////////

////////// Start Create Exam  //////////

export async function createNewExam(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/assignment/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function UpdatedExamByID(assignmentId, body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURLAPI}/assignments/teacher/update/${assignmentId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getExamListByCourse(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/assignments/subject/getAll`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function PostExam(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/assignments/subject/post`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

////////// End Create Exam  //////////

////////// start Create question by exam  //////////

export async function createNewQuestionByExam(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/question/create`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function fetchAllAssignments() {
  try {
    const response = await fetch(`${KAMPUSURLAPI}/assignments/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function updateNewQuestionByExam(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURLAPI}/question/staff/update`, {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function deleteByQtestionId(questionId) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURLAPI}/questions/staff/delete/${questionId}`,
      {
        method: `PUT`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getQuestionByAlls(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURLAPI}/questions/institution/getAll`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getQuestionByStaff(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURLAPI}/questions/institution/staff/getAll`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

////////// End Create question by exam  //////////

// export async function StudentAssignmentPublish(body){
//   const response = await fetch(`${KATBOOKURLV1}/`,{
//     method : "POST",
//     headers : {
//       "Content-Type" :"application/json",
//     },
//     body : JSON.stringify(body),
//   });

//   if(response.status === 200){
//     return await response.json();
//   }else if(response.status === 401 || response.status === 400){
//     return await response.json();
//   }else {
//     let errorResponse = await response.json();
//     throw new Error(errorResponse.error);
//   }

// }

export async function getAllStudentAssignments(body, token) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/assignments/students/getAll/subjects`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function getAssignmentQuestion(body) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/questions/institution/staff/getAll`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    console.log(err, "ASASSASASASCv");
    throw new Error(err);
  }
}

export async function postAnswersByStudent(body) {
  try {
    const response = await fetch(`${KAMPUSURLAPI}/student/answers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function getStudentPreviouslyAnsweredQuestion(body) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/admin/student/answers/getAll`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 400 ||
      response.status === 401
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function postSingleAnswerByStudent(body) {
  try {
    const response = await fetch(`${KAMPUSURLAPI}/student/answers/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

//////////// login /////////////

export async function loginUser(body) {
  try {
    const response = await fetch(`${KAMPUSURLAPI}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

//////////// login /////////////

//////////// fetch iskcon subjects /////////////

export async function fetchSUbjects(body) {
  try {
    const response = await fetch(`${KATBOOKURLV1}/kampus/isckon/book/getAll`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function ExamSubmissionByStudent(body) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/students/assignment/submission`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

//////////// login /////////////

export async function GetAllProgressByStudent(userID) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/content/student/get/progress/${userID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function GetSessionCountbySubject(subjectID) {
  try {
    const response = await fetch(
      `${KATBOOKURLV1}/content/session/get/${subjectID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function GetQuestionCountbySubject(subjectID) {
  try {
    const response = await fetch(
      `${KATBOOKURLV1}/content/questions/get/${subjectID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function GetAssessmentAnswerByStudent(userLoginID) {
  try {
    const response = await fetch(
      `${KATBOOKURLV1}/student/assessments/${userLoginID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function GetlastSessionByStudent(userLoginID) {
  try {
    const response = await fetch(
      `${KAMPUSURLAPI}/user/session/lastviewed/${userLoginID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function GetAllStudent(token) {
  try {
    const response = await fetch(`${KAMPUSURLAPI}/admin/students/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}
