import React, {useEffect,useState,} from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Row, Col } from 'reactstrap';
import useResponsive from "../../hooks/useResponsive";
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import parse from "html-react-parser";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import data from "../../../src/data.json";
import {
  createQuestionType,
  getQuestionTypeByInst,
  updateQuestionType,
  getTeacherSubjects,
  createNewExam,
  createNewQuestionByExam,
  getQuestionByStaff,
  fetchSUbjects
} from "../../services/Apiservices"
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";

const Assignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  const { isBigScreen } = useResponsive();

  const EXISTING_QUESTION_BANK_CODE = "EQBC";
  const NEW_QUESTION_BANK_CODE = "NQBC";
  const ADD_QUESTION_DISPLAY = "AQD";

  const [showScreen, setShowScreen] = React.useState({
    show: false,
    Type: "",
  });
  const [question, setQuestion] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState([]);
  const [AsstTitle, setAsstTitle] = useState('');
  const [AsstMarks, setAsstMarks] = useState('');
  const [userscredsData, setuserscredsData] = useState([]);

  const [asstAddBtn, setasstAddBtn] = useState(false);
  const [questionTypesOption, setQuestionTypesOption] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [questionTypeName, setQuestionName] = useState('');
  const [edit, setEdit] = useState(false)
  const [QTupdateBtn, setQTupdateBtn] = useState(false)
  const [QTadd, setQTadd] = useState(false)
  const [detailtedans, setDetailtedans] = useState("");

  const [editOPtion, setEditOption] = useState(false)
  const [editOPtionText, setEditOptionText] = useState('')
  const [optionText, setoptionText] = React.useState("");
  const [option, setoption] = React.useState([]);
  const [correctAns, setCorrectAns] = React.useState({});
  const [textAns, setTextAns] = React.useState("");
  const [quesAns, setquesAns] = React.useState({});
  const [QuestionValue, setQuestionValue] = useState("");
  const [ExamsData, setExamsData] = useState([]);
  const [AsstQuestionsData, setAsstQuestionsData] = useState([]);
  const [Quesmarks, setQuesmarks] = useState('');
  const [instructions,setInstructions] = useState('')
  const optionsAns = [
    {
      value: "TextBox",
      id: "1",
      label: "TextBox",
    },
    { value: "TextArea", id: "2", label: "TextArea" },
    { value: "RadioButton", id: "3", label: "RadioButton" },
  ];
  const [AnswerType, setAnswerType] = useState({});
  const [getAllQues, setgetAllQues] = useState([]);

  const CourseListFunc = async (userType) => {

    if(userType === 'TEACHER'){

      let allquestres = await  fetchSUbjects();
      if(allquestres.units.length > 0){
        let dataOPtion = await allquestres.units.map((e) => {
          return { _id: e._id, age: e.age, bookName: e.book, value: e.age+' - '+e.book, label: e.age+' - '+e.book };
        });

        setsubjectsData(dataOPtion);
      }

    } else {

      let allquestres = await  fetchSUbjects();
      if(allquestres.units.length > 0){
        let dataOPtion = await allquestres.units.map((e) => {
          return { _id: e._id, age: e.age, bookName: e.book, value: e.age+' - '+e.book, label: e.age+' - '+e.book };
        });

        setsubjectsData(dataOPtion);
      }

    }

    
  }

  const getAllQuestionsFuc = async () => {
    let result;
    let body = {
      assignmentId: ExamsData._id,
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user._id,
    };
    try {
      result = await getQuestionByStaff(body);
      if (result.success) {
        console.log(result,"GET ALL QUESTION DATAS");
        setgetAllQues(result.data);
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const EmptyAssigtCreateData = () => {
    // alert();
    setsingleSubject('')
    setAsstTitle('')
    setAsstMarks('')
    setQuestionValue('')
    setQuestionType('')
    setQuesmarks('')
    setoption([])
    setTextAns('')
    setquesAns('')
    setCorrectAns('')
    setQTupdateBtn(false)
    setasstAddBtn(false)
    setShowScreen({
      show: false,
      Type: '',
    });
  }

  const EmptyQuesData = () => {
    setQuestionValue('')
    setQuestionType('')
    setQuesmarks('')
    setoption([])
    setTextAns('')
    setquesAns('')
    setCorrectAns('')
    setQTupdateBtn(false)
  }

  const CreateAssignmentNew = async () => {
    // alert()
    let result;
    let body = {
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user._id,
      staffName: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user.fullName,
      staffDesignation: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user.userTypeId.userType,
      katbookSubjectId: singleSubject._id,
      katbookSubjectName: singleSubject.bookName,
      ageGroup: singleSubject.age,
      examName: AsstTitle,
      totalMarks: AsstMarks,
      examDate: "",
      examStartTime: "",
      examEndTime: "",
     };
     try {
      result = await createNewExam(body);
      if (result.success) {
        console.log(result,"CREATE NEW EXAMS");
        setExamsData(result.data)
        console.log(ExamsData,"CREATE NEW EXAMS");
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createNewQuestionsFunc = async () => {


    let optArr = [];
    for (let a = 0; a < option.length; a++) {
      const e = option[a];

      optArr.push({ option: e.name });
    }

    let result;
    let body = {
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).user._id,
      assignmentId: ExamsData._id,
      questionTypeId: questionType._id,
      question: QuestionValue,
      answerType: AnswerType.value,
      correctAnswer: AnswerType.value === "RadioButton" ? correctAns.name : textAns || quesAns,
      marks: Quesmarks,
      options: optArr
     };
     try {
      result = await createNewQuestionByExam(body);
      if (result.success) {
        console.log(result,"CREATE NEW Questions BY Exams");
        // setAsstQuestionsData(result.data)
        setQuestion(true);
        setShowScreen(false);
        ShowAddQuestions();
        EmptyQuesData()
        getAllQuestionsFuc()
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const createQuestionTypeFuc = async () => {
    let result;
    let body = { institutionId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID, questionTypeName : questionTypeName }
    try {
      result = await createQuestionType(body);
      if (result.success) {
        console.log(result,"CREATE QUESTION TYPE NAME");
        toast.success(<Success body={result.message} />);
        questionCancelFuc();
        setEdit(false)
        setQTadd(false)
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateQuestionTypeFuc = async () => {
    let result;
    let body = { questionTypeId: questionType._id, questionTypeName: questionTypeName }
    try {
      result = await updateQuestionType(body);
      if (result.success) {
        toast.success(<Success body={result.message} />);
        questionCancelFuc();
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAllQuestionTypeFuc = async () => {
    let result;
    try {
      result = await getQuestionTypeByInst();
      if (result.success) {
        console.log(result,"GET ALL QUESTION TYPES");
        let dataOPtion = await result.data.map((e) => {
          return { value: e.questionTypeName, label: e.questionTypeName, _id: e._id };
        });

        setQuestionTypesOption(dataOPtion);
      } else {
        toast.error(<Danger body={result.error} />);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const ShowAddQuestions = () => {
    setShowScreen({
      show: true,
      Type: ADD_QUESTION_DISPLAY,
    });
  };

 
  const showNewQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: NEW_QUESTION_BANK_CODE,
    });
  };

  useEffect(() => {

    if(localStorage.length > 0){

      let retrData = JSON.parse(localStorage.userCreds);
      setuserscredsData(retrData);
      console.log(retrData,"USERRRRRRRRRRRRRRRRRRRRRRRR");

      CourseListFunc(retrData.user.userTypeId.userType)

    }
    
  },[]);

  const questionTypeSaveFuc = () => {
    // console.log(questionTypeName.length,"LLLLLLLLLLLLLLLL");
    if (questionTypeName.length > 0) {
      if (questionType) {
        if ((questionType && questionTypeName !== questionType.questionTypeName)) {
          updateQuestionTypeFuc()
        } else {
          toast.error(<Warning body={"Please edit something to update question type name!."} />)
        }
      } else {
        createQuestionTypeFuc()
      }
    }
    else {
      toast.error(<Warning body={"Please enter question type name!."} />)
    }
  }

  const CreateAssignmentFunc = () => {

    console.log(singleSubject,"XX CREATE ASSIGNMENT");
    console.log(AsstTitle,"XX CREATE ASSIGNMENT");
    console.log(AsstMarks,"XX CREATE ASSIGNMENT");

    if(singleSubject.length === 0){
      toast.error(<Warning body={"Please select course"} />)
    } else if(AsstTitle === ''){
      toast.error(<Warning body={"Please enter exam name"} />)
    }  else if(AsstMarks === ''){
      toast.error(<Warning body={"Please enter total marks"} />)
    } else {
      // alert();
      CreateAssignmentNew();
      setasstAddBtn(true)
      ShowAddQuestions();
    } 
  }

  const questionCancelFuc = (update) => {
    if (update) {
      setQuestionName(update.value);
      getAllQuestionTypeFuc();
    }
    else {
      setEdit(false);
      setQTadd(false);
      setQTupdateBtn(false)
      setQuestionType('');
      setQuestionTypesOption('');
      setQuestionName('');
      getAllQuestionTypeFuc();
      setDetailtedans('');
    }
  }

  const selectQuestNames = (question) => {
    setQuestionName(question);
  }

  const onChangeQuestion = async (name, e) => {
    console.log("ASDSAD", name, e);
    setQuestionValue(e);
    if (e !== "") {
    }
  };

  const onChangeAnswerType = async (name, e) => {
    setEditOption(false);
    setEditOptionText('')
    console.log(name, e);
    setoption([]);
    setCorrectAns({});
    setTextAns("");
    setAnswerType(e);
  };

  const addoption = async () => {
    console.log(optionText, "tttt");
    console.log(option, "tttt");
    console.log(optionText.trim(), "tttt");
    if (optionText !== "") {
      var count = 0;
      await option.map((data) => {
        if (optionText.trim() !== data.name) {
          count = count + 1;
        }
        return 1;
      });
      console.log("count", option.length, count);
      if (option.length !== count) {
        toast.warning(<Warning body={"Already this option value was added"} />);
      } else {
        console.log(optionText, "jjj");
        await setoption([
          ...option,
          { _id: count + 1, name: optionText.trim(), value: parse(optionText.trim()), label: parse(optionText.trim())  },
        ]);
        await setoptionText('')
        await setCorrectAns('');
      }
    }
  };

  const editOPtionFuc = async () => {
    console.log(optionText, "tttt");
    const findIndex = await option.findIndex(x => {
      if (String(x._id) === String(editOPtionText._id)) { return true; } return false;
    });
    if (findIndex >= 0) {
      if (optionText !== "") {
        var count = 0;
        await option.map((data) => {
          if (optionText.trim() !== data.name) {
            count = count + 1;
          }
          return 1;
        });
        if (option.length !== count) {
          toast.warning(<Warning body={"Already this option value was added"} />);
        } else {
          console.log(optionText, "jjj");

          if (String(option[findIndex].name).toLocaleLowerCase().trim() !== String(optionText).toLocaleLowerCase().trim()) {
            let data = await option.map(x => {
              if (String(x._id) === String(editOPtionText._id)) {
                return { _id: x._id, name: optionText.trim(), value: parse(optionText.trim()), label: parse(optionText.trim()) }
              }
              else {
                return { _id: x._id, name: x.name.trim(), value: parse(x.name.trim()), label: parse(x.name.trim()) }
              }
            });

            await setoption('');
            await setoptionText('');
            await setCorrectAns('');
            await setEditOptionText('');
            await setEditOption('');
            await setoption(data);
            console.log(data,"tttt");
          }
          else {
            toast.warning(<Warning body={'Edit something to update the option!'} />);
          }
        }
      }
    }
    else {
      toast.warning(<Warning body={"Select vaild option text!"} />);
    }
  };

  const onChangeFuc = (e) => {
    console.log(e);
    if (editOPtion) {
      setoptionText(e);
    }
    else {
      setoptionText(e);
    }
  };

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.authorizedPerson : JSON.parse(localStorage.userCreds).user.fullName : ""}
        ShowNotifications={ShowNotifications}
        userName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userName : ""}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        userType = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType : ""}
      />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          // style={{
          //   height: window.innerHeight - 117 + "px",
          //   overflowY: "auto",
          // }}
        >
          <KHeader title="Create Exam " containerClassName={`marginTop-20`} />
          <div className="container-fluid px-5 py-3">
            {/* Create Assignment Component Starts With Responsive */}
            <div className="d-flex flex-column flex-md-row">
              <KDropdown
                containerClassName="w-100"
                label="Select Course"
                isRequired={true}
                placeholder="Select Course"
                value={singleSubject}
                options={subjectsData}
                onChange={(e) => {
                  setsingleSubject(e);
                  }}
              />
              <KInput
                label="Exam Name"
                isRequired={true}
                placeholder="Enter Exam Name"
                width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                onChange={setAsstTitle}
                value={AsstTitle}
              />
              <KInput
                label="Total Marks"
                isRequired={true}
                placeholder="Enter Total Marks"
                containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                  isBigScreen ? "w-50" : "w-100"
                }`}
                labelClassName={"width-maxContent"}
                inputClassName="w-100"
                type="number"
                value={AsstMarks}
                onChange={setAsstMarks}
                toast={toast}
                Warning={Warning}
              />
              <div className={`${asstAddBtn ? "" : "w-100"} pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer`}>
                <KButton
                  onClick={() => {
                    CreateAssignmentFunc()
                  }}
                  value="ADD"
                  className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                  isDisabled={asstAddBtn}
                />
              </div>
              {asstAddBtn ? (
              <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                <KButton
                  onClick={() => {
                    EmptyAssigtCreateData()
                  }}
                  value="CANCEL"
                  className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                  isDisabled={false}
                  btnColor="red"
                />
              </div>
              ) : (
                <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer"></div>
              )
              }
            </div>
          </div>
          {/* Create Assignment Component Ends */}
             {/* <KHeader 
                height={100}                 
                title="Instruction for Students"
              
              />
          <div className="container-fluid px-5 py-3">
            <KEditor
              initialValue={
                  ""}
              value={instructions}
              height={400}
                  onChange={(value) => {
                    setInstructions(value);
                }}
                
                /> 

           </div> */}
            
          {showScreen.show && (
            <React.Fragment>
              
              <div className="container-fluid px-5 py-3">
             
              </div>
              {/* Add Questions Starts With Responsive */}
              <KHeader title="Add Questions" />
              <div className="container-fluid px-5 py-3">
                <div className="d-flex flex-column flex-md-row">

                  {/* <KButton
                    value="Existing Question Bank"
                    className={`px-6 py-3 mr-3  ${
                      isBigScreen ? "w-25" : "w-100"
                    }`}
                  /> */}

                  <KButton
                    value="Create New Question Bank"
                    className={`px-6 py-3 ${
                      isBigScreen ? "w-25" : "w-100 mt-2"
                    }`}
                    onClick={() => {
                      showNewQuestionBank();
                      getAllQuestionTypeFuc();
                      console.log(ExamsData,"CREATE NEW EXAMS")
                    }}
                  />
                </div>
                {showScreen.Type == NEW_QUESTION_BANK_CODE && (
                  <React.Fragment>
                    {/* 
  Creation Of Question Header
*/}

            <div className="d-flex flex-column flex-md-row marginTop-20">
            {(!edit && !QTadd) && <KDropdown
                containerClassName="w-100"
                label="Select Question Type"
                isRequired={true}
                edit={questionType ? true : false}
                value={questionType && questionType}
                options={questionTypesOption}
                placeholder="Select Question Type"
                labelStyle={{
                  fontSize: "20px",
                  marginBottom: "15px"
                }}
                onChange={(e) => {
                    // setUpdateData(data)
                    console.log(e.value,"oooooooooooooooooo")
                    setQuestionType(e);
                    setQTupdateBtn(true)
                    // setquesAns("");
                  }}
              />
            }
            {(edit || QTadd) && <KInput
                label="Question Type  Name"
                isRequired={true}
                placeholder="Enter the Question Type  Name"
                // width={"300px"}
                containerClassName="pl-0 pt-2 pt-md-0 w-100"
                value={questionTypeName && questionTypeName}
                onChange={(e) => {
                  selectQuestNames(e)
                }}
                SaveData={() => {
                  questionTypeSaveFuc();
                }}
              />
            }
              <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
              {(!QTadd) && <KButton
                onClick={() => {
                  if(QTupdateBtn){
                    questionCancelFuc(questionType);
                    setQTadd(true)
                    setEdit(true)
                  } else {
                    questionCancelFuc();
                    setQTadd(true)
                  }
                  
                }}
                  value={QTupdateBtn ? "UPDATE" : "ADD"}
                  className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                  isDisabled={false}
                />
                }
                {(edit || QTadd) && <KButton
                onClick={() => {
                  // console.log(QTadd,"LLLLLLLLLLLLLLLL");
                  if(QTadd){
                    // console.log(QTadd,"LLLLLLLLLLLLLLLL");
                    questionTypeSaveFuc()
                  } else {
                    questionCancelFuc();
                    setEdit(true)
                  }
                }}
                  value={edit ? "CONFIRM" : "CONFIRM"}
                  className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                  isDisabled={false}
                />
                }
                {(edit || QTadd) && <KButton
                onClick={() => {
                  questionCancelFuc();
                }}
                value="CANCEL"
                className={`ml-3 px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                isDisabled={false}
                btnColor="red"
                txtColor="#fff"
                />
                }
              </div>
            </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="d-flex align-items-end mb-2">
                          {/* <IoMdArrowRoundBack
                            className="cursor-pointer"
                            onClick={() => ResetToDefaultScreen()}
                            size={25}
                          /> */}
                        </div>
                        {/* <KHeader title="New Question Bank" /> */}
                      </div>
                      <div className="d-flex align-items-end mb-2">
                        {/* <KButton value="Create" className={`px-3 py-1`} /> */}
                      </div>
                    </div>
                    {/* 
  Creation Of Question Header Ends
*/}
                    <div className="d-flex flex-row justify-content-between my-3">
                      <KRequiredLabel
                        title="Enter Question"
                        className="h5 mt-3"
                        style={{
                          display: "inline-block",
                          alignSelf: "flex-end"
                        }}
                      />
                      <KInput
                        label="Marks"
                        isRequired={true}
                        placeholder="Enter Marks"
                        containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 w-25`}
                        labelClassName={"width-maxContent"}
                        inputClassName="w-25"
                        type="number"
                        value={Quesmarks}
                        onChange={setQuesmarks}
                        toast={toast}
                        Warning={Warning}
                      />
                    </div>
                    <KEditor 
                    height={isBigScreen ? 400 : 500}
                    value={QuestionValue}
                    handleEditorChange={(
                          content
                        ) => {
                          onChangeQuestion("Question", content)
                        }}

                    />
                    <KDropdown
                      labelStyle={{
                  fontSize: "20px",
                  marginBottom: "15px"
                }}
                      containerClassName={`marginTop-20 ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      label="Answer Type"
                      isRequired={true}
                      placeholder="Select Answer Type"
                      options={optionsAns}
                      value={AnswerType}
                      onChange={(e) => onChangeAnswerType("AnswerTypes", e)}
                      isDisabled={QuestionValue ? false : true}
                      
                    />

{AnswerType && AnswerType.value === "RadioButton" && (
                    
                      <>
                      <Row style={{
                      marginTop: '17px',
                      color: 'black',
                    }}>
                      <Col sm="10" md="10" lg="7">
                        <div
                          style={{ marginBottom: "20px", color: "black" }}
                        >
                          <KRequiredLabel
                              title="Options"
                              className="h5 mt-3"
                              style={{
                                display: "inline-block",
                                alignSelf: "flex-end"
                              }}
                            />
                        </div>
                        {/* <CInput
                              style={{ marginTop: '8px' }}
                              onChange={(e) => {
                                onChangeFuc(e);
                              }}
                              value={optionText}
                              placeholder={"Options"}
                            /> */}
                        <KEditor
                        
                          height={isBigScreen ? 400 : 500}
                          value={optionText}
                          handleEditorChange={(
                            content
                          ) => {
                            onChangeFuc(content);
                          }}
                        />
                        </Col>

<Col sm="2" md="2" lg="4">
<KButton 
                        width={60} 
                        height={50} 
                        value={editOPtion ? 'UPDATE' : 'ADD'}
                        className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                        style={{
                          position: 'absolute',
                          bottom: '0px',
                          marginBottom: '20px'
                        }} 
                        onClick={() => {
                          if (optionText) {
                            if (editOPtion) {
                              editOPtionFuc();
                            }
                            else {
                              addoption();
                            }
                          } else {
                            toast.warning(<Warning body={"Enter Your Option Name"} />);
                          }
                        }} 
                        />
                          </Col>

</Row>
                          </>
                  )}

                  {option.length !== 0 &&
                    AnswerType.value === "RadioButton" && (
                      <Row style={{
                        marginTop: '17px',
                        color: 'black',
                        paddingLeft: '18px'
                      }}>
                        <Col sm="12" md="6" lg="3" style={{ marginTop: '15px', marginBottom: '15px' }}>
                        {/* <div style={{ marginBottom: '10px', color: 'black' }}>
                            <h6 style={{ fontWeight: 'bold' }}>{name}<span className="text-danger">{star}</span></h6>
                        </div> */}
                        {/* <KRequiredLabel
                              title="Correct Answers in Options"
                              className="h5 mt-3"
                              style={{
                                display: "inline-block",
                                alignSelf: "flex-end"
                              }}
                            /> */}
                        <KDropdown
                          label={"Correct Answers in Options *"}
                          options={option}
                          // value={correctAns}
                          value={correctAns}
                          onChange={(e) => {
                            setCorrectAns(e);
                            console.log(e,"WWWWWWWWWWWWWW");
                          }}
                          placeholder={"Select correct answer"}
                        />
                        </Col>
                        <Col sm="2" md="2" lg="2">
                          {!editOPtion ? <KButton width={60} height={50} className="btn btn-info" style={{
                            position: 'absolute',
                            bottom: '0px',
                            marginBottom: '20px'
                          }} onClick={() => {
                            if (correctAns && correctAns._id) {
                              console.log(correctAns);
                              setEditOption(true);
                              setEditOptionText(correctAns);
                              setoptionText(correctAns.name)
                            }
                            else {
                              toast.warning(<Warning body={'Please edit an option which your are edit!.'} />)
                            }
                          }} 
                          value="EDIT"


                          />
                            : <KButton width={60} height={50} className="btn btn-danger" style={{
                              position: 'absolute',
                              bottom: '0px',
                              marginBottom: '20px'
                            }} onClick={() => {
                              setEditOption(false);
                              setEditOptionText('');
                              setoptionText('');
                            }}
                            value="CANCEL"
                            />
                          }
                        </Col>

                      </Row>
                    )}


        
                    {AnswerType &&
                    (AnswerType.value === "TextBox" ||
                    AnswerType.value === "TextArea") ? (
                      <>
                    <KRequiredLabel
                      title="Correct Answer"
                      className="h5 marginTop-20"
                    />
                    <KEditor 
                    height={isBigScreen ? 400 : 500}
                    value={textAns || quesAns}
                    handleEditorChange={(
                      content
                    ) => {
                      setquesAns(content);
                    }}

                    />
                    </>
) : null}
                    <div className="d-flex flex-column flex-md-row mt-3" style={{ paddingTop: "30px", paddingBottom: "100px", float: "right"}}>
                      <KButton
                        value="SAVE"
                        onClick={() => {
                          createNewQuestionsFunc()
                        }}
                        btnColor={"green"}
                        className={`px-4 py-2 `}
                      />
                      <KButton
                        value="CANCEL"
                        className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                        btnColor={"red"}
                      />
                    </div>
                  </React.Fragment>
                )}

                </div>

                {/* Question & Answers with Responsive Starts */}

                <div className="container-fluid px-3 py-3">

                {question && (
                  <div>
                    <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                      <KHeader
                        title="Questions & Answers"
                        style={{
                          marginLeft: "0px !important",
                        }}
                      />
                      <KButton
                        value="Add"
                        onClick={() => {
                          setQuestion(false);
                          showNewQuestionBank();
                        }}
                        className={"height-fitContent px-4 py-2 "}
                      />
                    </div>

                    {getAllQues.map((items, index) => (
                      <div className="mx-5 my-3">
                        <div className="d-flex flex-row gap-3 align-items-center">
                          <div className="DivBox">
                            {/* Question 1 */}
                            {parse(items.question)}
                            {items.options.map((q, index) => (
                              <div>{parse(q.option)}</div>
                            ))}
                            {/* {parse(items.options)} */}
                          </div>

                          <KInput placeholder="Enter Marks" type="text" value={items.marks} />
                          <div
                            style={{
                              display: "flex",
                              paddingLeft: "145px",
                              gap: "30px",
                            }}
                          >
                            <div className="borderCircleEdit">
                              <AiOutlineEdit size="25" />
                            </div>

                            <div className="borderCircleDelete">
                              <RiDeleteBin6Line size="23" />
                            </div>
                          </div>
                        </div>

                        <div className="DivBoxBig mt-3 mb-5">
                          {/* Answer 1 */}
                          {parse(items.correctAnswer)}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Question & Answers with Responsive Ends */}
              </div>
              {/* Add Questions Starts With Responsive Ends */}
            </React.Fragment>
          )}
        </div>
      </div>

      <Footer
        // openForm={openForm}
        // shoq={shoq}
        // closeForm={closeForm}
        // KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default Assignment;
