// import "./styles.css";
import React from "react";
import { Editor } from "@tinymce/tinymce-react";
export default function KEditor({
  height,
  value,
  handleEditorChange,
  onChange,
  initialValue,
}) {
  console.log(height, "KEDITOEHEIGHT");
  const editorRef = React.useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        apiKey="q2qyvqwqk85s2i1jivbs5l70kbz1m13e0c5q4xfkbuzekx00"
        // onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        onEditorChange={(e) => {
          onChange(e);
          handleEditorChange(e);
        }}
        init={{
          content_css:
            "https://fonts.googleapis.com/css?family=Open+Sans&display=swap",
          content_style: "body { font-family: Nunito; }",
          selector: "textarea#custom-menu-item",
          height: height,
          width: "100%",
          skin: "bootstrap",
          icons: "bootstrap",
          plugins: [
            " advlist autolink lists link image charmap print preview anchor line",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount contextmenu hr ",
          ],
          font_formats:
            "Nunito=Nunito;",
          fontsize_formats:
            "2px 4px 6px 8px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 39px 40px",
          toolbar1:
            "h1 h2 h3 h4 h5 h6 P |equation| styleselect | commentInsertButton clarifyInsertButton  | bold italic underline strikethrough | bullist numlist | select | alignleft aligncenter alignright alignjustify  outdent indent | blockquote  pastetext superscript subscript removeformat  forecolor|  backcolor  undo redo   |fontselect fontsizeselect | advlist  image link media mediaembed  insertfile  | multiplelink multipleVideoLink  VideoLink multipleAudioLink | UploadVideoFile UploadImageFile UploadAudioFile UploadFile |  menuDateButton  table  charmap | wordcount code help | Carousel | line | lineheight",
          toolbar_mode: "floating",
          lineheight_formats:
            "0.1 0.2 0.3 0.4 0.5 0.6 0.7 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 1.8 2 2.1 2.2 2.3 2.4 2.5 2.6 2.7 2.8 2.9 3",
          line: true,
          contextmenu_avoid_overlap: ".mce-spelling-word",
          block_formats:
            "Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre;Paragraph=p;",
          contextmenu:
            "undo redo | inserttable pastetext link | forecolor backcolor | styleselect ",
          advlist_bullet_styles: "default,circle,disc,square", //bullist numlist outdent indent advlist
          image_advtab: true,
          init_instance_callback: function (editor) {
            editor.on("keydown", function (e) {
              if (e.keyCode === 9) {
                e.preventDefault();
                editor.execCommand(
                  "mceInsertContent",
                  false,
                  "&nbsp;&nbsp;&nbsp;&nbsp;"
                );
              }
            });
          },
          paste_as_text: true,
          /* enable title field in the Image dialog*/
          // image_title: true,
          // media_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          media_live_embeds: true,
          //content_style: "a { color:#fff;background-color:#337ab7;border-color:#2e6da4;height: 28px;font-size: 14px;padding: 3px 8px;}",
          /*
              URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
              images_upload_url: 'postAcceptor.php',
              here we add custom filepicker only to Image dialog
            */
          media_url_resolver: function (data, resolve /*, reject*/) {
            if (data.url.indexOf("YOUR_SPECIAL_VIDEO_URL") !== -1) {
              console.log(data, "data2");
              var iframe = "iframe";
              var embedHtml =
                '<iframe id="' +
                iframe +
                '" src="' +
                data.source +
                '" width="' +
                data.width +
                '" height="' +
                data.height +
                '" controls controlsList="nodownload" ></iframe>';
              resolve({ html: embedHtml });
            } else {
              resolve({ html: "" });
            }
          },
          video_template_callback: function (data) {
            var iframe = "iframe";
            return (
              '<iframe id="' +
              iframe +
              '" src="' +
              data.source +
              '" width="' +
              data.width +
              '" height="' +
              data.height +
              '"  controls controlsList="nodownload" ></iframe>'
            );
          },

          // file_picker_types: 'file image media',
          // file_browser_callback_types: 'file image media',

          /* and here's our custom image picker*/
          // file_picker_callback: function (cb, value, meta) {
          //   var input = document.createElement('input');
          //   if (meta.filetype === 'image') {
          //     input.setAttribute('type', 'file');
          //     input.setAttribute('accept', 'image/*');
          //   }

          //   if (meta.filetype === 'media') {
          //     input.setAttribute('type', 'file');
          //     input.setAttribute('accept', 'video/*');
          //   }

          //   if (meta.filetype === 'file') {
          //     input.setAttribute('type', 'file');
          //     input.setAttribute('accept', 'file/*');
          //   }
          //   /*
          //     Note: In modern browsers input[type="file"] is functional without
          //     even adding it to the DOM, but that might not be the case in some older
          //     or quirky browsers like IE, so you might want to add it to the DOM
          //     just in case, and visually hide it. And do not forget do remove it
          //     once you do not need it anymore.
          //   */

          //   input.onchange = function () {
          //     var file = this.files[0];
          //     console.log(file, 'files');
          //     var reader = new FileReader();
          //     reader.onload = function () {
          //       /*
          //         Note: Now we need to register the blob in TinyMCEs image blob
          //         registry. In the next release this part hopefully won't be
          //         necessary, as we are looking to handle it internally.
          //       */
          //       // var id = 'blobid' + (new Date()).getTime();
          //       // var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
          //       // var base64 = reader.result.split(',')[1];
          //       // var blobInfo = blobCache.create(id, file, base64);
          //       // blobCache.add(blobInfo);

          //       // /* call the callback and populate the Title field with the file name */
          //       // console.log(blobInfo);
          //       // cb(file, { title: file.name, file: file });
          //     };
          //     reader.readAsDataURL(file);

          //   }
          //   input.click();
          // },
          // setup: (editor) => embed(editor)
        }}
        // init={{
        //   height: 500,
        //   menubar: false,
        //   plugins: [
        //     "advlist",
        //     "autolink",
        //     "lists",
        //     "link",
        //     "image",
        //     "charmap",
        //     "preview",
        //     "anchor",
        //     "searchreplace",
        //     "visualblocks",
        //     "code",
        //     "fullscreen",
        //     "insertdatetime",
        //     "media",
        //     "table",
        //     "code",
        //     "help",
        //     "wordcount"
        //   ],
        //   toolbar:
        //     "undo redo | blocks | " +
        //     "bold italic forecolor | alignleft aligncenter " +
        //     "alignright alignjustify | bullist numlist outdent indent | " +
        //     "removeformat | help",
        //   content_style:
        //     "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
        // }}
        value={value}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  );
}

KEditor.defaultProps = {
  height: 1200,
  value: "",
  onChange: () => {},
};
