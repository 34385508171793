import "../../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { HiOutlineMail, HiOutlineLogout } from "react-icons/hi";
import { IoLogoBuffer } from "react-icons/io5";
import { Authcontext } from "./Context/Context";
import ShLogo from "../../Assets/ISK_logo.png";
import KAMUEDU from "../../Assets/KampusUeducate.png";
import StLogo from "../../Assets/student_pic.png";
import { AiOutlineAppstore } from "react-icons/ai";
import { FiBookOpen } from "react-icons/fi";
import { BiTime } from "react-icons/bi";
import { MdInsertChartOutlined } from "react-icons/md";
import SubHeader from "../components/Menu/SubHeader";

import Notify from "../../Assets/Noti_Img_1.png";
import Notify_Img from "../../Assets/Noti_Img_2.png";
import { TbArrowLeft } from "react-icons/tb";

import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";

// import {} from 'react-icons/hi'
const HeaderNavbar = ({
  showProfile,
  notofShowProfile,
  falseShowProfile,
  translateText,
  // institutionName,
  profileIcon,
  username,
  standard,
  dropdownEmail,
  dropdownUser,
  setCollapse,
  collapse,
  katbookPageToggel,
  SearchToggle,
  IndexToggle,
  HideFuc,
  BookToggle,
  CardToggle,
  exitAddCont,
  bookschatexitFun,
  falseShowLanguage,
  FalseShowSessions,
  clarifyfullview,
  setClarifyFullView,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  trueShowNotification,
  userType,
  personName,
  userName,
  userData,
}) => {
  // const [showNotification, setNotification] = useState(false);
  // const [showProfile, setShowprofile] = useState(false);
  const navigate = useNavigate();
  const [showSchoolToolTip, setShowSchoolToolTip] = useState(false);

  const location = useLocation();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  // console.log(location.pathname,"ROUTES NAMEEEEEEEEEEEEEEEEEEEEEEEEE",userType);

  React.useEffect(() => {
    // console.log(localStorage,"LOCAL STORAGE");
    if (localStorage.length > 0 && localStorage.userCreds !== undefined) {
      // console.log(localStorage.userCreds)
      try {
        let retrData = JSON.parse(localStorage.userCreds);

        if (retrData) {
          const decodedJwt = parseJwt(retrData.token);
          if (decodedJwt.exp * 1000 < Date.now()) {
            // toast.warning(<Warning body={"Your session expired. Login again"} />);
            navigate("/login");
          }
          // dispatch({type:"LOGIN",payload :retrData})
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [location]);

  const [dark, setDark] = useState(false);

  const [light, setLight] = useState(false);

  const darkMode = () => {
    setDark(true);
    setLight(false);
  };

  const lightMode = () => {
    setDark(false);
    setLight(true);
  };

  // const ShowProfile = () => {
  //   // setShowprofile(!showProfile);
  //   notofShowProfile();
  //   // setNotification(false);
  // }
  // const ShowNotification = () => {
  //   setNotification(!showNotification);
  //   falseShowProfile();
  // }
  // console.log(profileIcon,"profileIcon");
  const ShowProfile = () => {
    notofShowProfile();
  };
  const ShowNotification = () => {
    ShowNotifications();
  };
  // const { state, dispatch } = React.useContext(Authcontext);
  // console.log(profileIcon,"profileIcon",state);

  // const [active, setActive] = useState("12");

  // const [searchValue, setSearchValue] = useState(false);
  const [layoutView, setLayoutView] = useState(false);

  // let student = localStorage.getItem("student");

  const [showHover, setshowHover] = useState(false);
  // const [sideShow, setSideShow] = useState(true);

  const [zoomState, setZoomState] = useState(false);
  const [goToPage, setGoToPage] = useState(false);

  // const [showSessions, setSessions] = React.useState(false);
  // const [showLanguage, setShowLanguage] = React.useState(false);
  const [showBookmarks, setShowBookmarks] = React.useState(false);
  const [notes, setNotes] = React.useState(false);
  const [clarify, setClarify] = React.useState(false);

  const [showSearch, setShowSearch] = React.useState(false);
  const [showIndexDetails, setShowIndexDetails] = React.useState(false);
  const [showZoomsize, setZoomsize] = React.useState(false);
  // const [clarifyfullview, setClarifyFullView] = useState(false);

  const [ZoomIn, setZoomIn] = useState(false);
  const [ZoomOut, setZoomOut] = useState(false);

  // console.log(showNotification,"showNotification",clarifyfullview)
  //   const ShowClarify = () => {
  //     setClarifyFullView();
  //     setNotification(false)
  //     setShowBookmarks(false);
  //     // setShowLanguage(false);
  //     falseShowLanguage();

  //     // setSessions(false);
  //     FalseShowSessions();
  //     setClarify(false);
  //     setShowSearch(false);
  //     setShowIndexDetails(false);
  //     setZoomsize(false);
  //     setNotes(false);
  //     setCollapse(false);
  //     setZoomState(false);
  //     setZoomIn(false);
  //     setZoomOut(false);
  // }

  const logoutfunc = async () => {
    const goToHomePage = () => navigate("/login");
    goToHomePage();
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#E5E5E5",
        }}
      >
        <div className="navbar-container">
          {/* <div
        className='web-display-none'
        style={{
          marginLeft: '10px',
          marginRight: '10px',
        }}>
            <img 
            src={ShLogo}
            onMouseEnter={()=>setShowSchoolToolTip(true)}
            onMouseLeave={()=>setShowSchoolToolTip(false)}
              style={{
                width: '120px',
                borderRadius: '50%',
                marginRight: '10px',
                marginLeft: '10px',
              }}
              alt=""
            />
          </div> */}

          <div>
            <img
              src={ShLogo}
              style={{
                width: "200px",
              }}
              alt=""
            />
          </div>

          <div className={"Header_Dashboard schoolName"}>ISKCON BANGALORE</div>
          {/* <div className={"Header_Dashboard"} >

        <Link to={'/BookView'} className={location.pathname === '/BookView' ? "nav-link Book_Icon" : "Book_Icon"} >
          <FiBookOpen className={"menu_icon"} size="20" />
          Katbook
        </Link>

      </div> */}
          <div
            className={"Margin_Right_25px"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // marginRight: '15px',
            }}
          >
            {/* <div className={"M_Right"}>
          <FiBell
           onClick={()=>ShowNotification()} 
           className='notification-hover' style={{cursor:"pointer"}} size={20} />
        </div> */}
            <div
              onClick={ShowProfile}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginRight: '15px',
              }}
            >
              <div onClick={ShowProfile} className="cursor-hover">
                <img
                  src={StLogo}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  alt=""
                />
              </div>
              <div className="dropdown-profile" style={{ cursor: "pointer" }}>
                <div
                  className={"Name_Nikil"}
                  style={{ textTransform: "capitalize" }}
                >
                  {personName}
                </div>
                <div className={"Class_section"}>
                  {localStorage.length > 0 &&
                  userData.user.userTypeId.userType === "ADMIN"
                    ? "ADMIN"
                    : localStorage.length > 0 &&
                      userData.user.grade}
                </div>
              </div>
            </div>
            <div
              className="mob-display-none"
              onClick={ShowProfile}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              {showProfile ? (
                <RiArrowDropUpLine className="secondary-navbaricons" />
              ) : (
                <RiArrowDropDownLine className="dropdown-profile-hover secondary-navbaricons" />
              )}
            </div>
            <div
              className="mob-display-none"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
              class="vl"
            ></div>
            <div
              className="mob-display-none navbar-title"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <img
              src={KAMUEDU}
              style={{
                width: "140px",
              }}
              alt=""
            />
            </div>
          </div>
        </div>
        {showProfile ? (
          <div className="profile-dropdown-container">
            <div className="dropdown-profile-title">
              {translateText ? translateText.Account : "Account"}
            </div>
            <div
              className="dropdown-profile-sub-title"
              style={{
                paddingTop: "12px",
              }}
            >
              <FaUserAlt size={18} />
              <div className="dropdown-profile-sub-title-name">
                {personName}
              </div>
            </div>
            <div className="dropdown-profile-sub-title">
              {
                <>
                  <FaKey size={18} />
                  <div className="dropdown-profile-sub-title-name">
                    {userName}
                  </div>
                </>
              }
            </div>
            <hr className={"marginTop-10 marginBottom-10"} />
            <div
              className="dropdown-profile-sub-title"
              onClick={() => {
                logoutfunc();
              }}
              style={{
                paddingBottom: "10px",
              }}
            >
              <HiOutlineLogout size={18} />
              <div className="dropdown-profile-sub-title-name">
                {translateText ? translateText.logout : "Logout"}
              </div>
            </div>
          </div>
        ) : null}

        {localStorage.length > 0 &&
          userData.user.userTypeId.userType === "ADMIN" && (
            <div className={"main_subs Header_Dashboard "}>
              <SubHeader userData={userData} />
            </div>
          )}
      </div>
    </>
  );
};

HeaderNavbar.defaultProps = {
  showProfile: false,
  dropdownEmail: "",
  dropdownUser: "",
};

export default HeaderNavbar;
