// Pages Elements Split into 7 Parts Find it By Find

// Sections Available : 


// Npm Packages
import React,{Fragment,useState,useEffect,useRef} from "react";
import { useNavigate } from 'react-router-dom';
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";

// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { ExportCSV } from "./ExportCSV";
// Styles
import "./StudentAssignment.css"

// Icons
import {IoIosArrowBack} from "react-icons/io"
import {AiOutlineFileAdd} from "react-icons/ai"

// Assets
import CompletedTick from "../../Assets/OnceUpon.png"

// Custom Components
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";
import Timer from "./Timer"

import {BsArrowLeft,BsArrowRight} from "react-icons/bs"

// Services
import {
  getAllStudentAssignments,
  getAssignmentQuestion,
  postAnswersByStudent,
  postSingleAnswerByStudent,
  getStudentPreviouslyAnsweredQuestion,
  ExamSubmissionByStudent
} from "../../services/Apiservices"



const StudentAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {

  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();
  const navigate = useNavigate();


  // SetState Initialization
  const [csvStructure, setCsvStructure] = useState([]);
  
  const counterRef = useRef(null)
  const [answerEditor, setAnswerEditor] = useState([]);
  const [draftCollection,setDraftCollection] = useState([]);
  const [responseCollection, setResponseCollection] = useState([]);
  
  const [tableAssignmentDisplay, setTableAssignmentDisplay] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState('');
  const [assignmentName, setAssignmentName] = useState({
                          subjectName: '',
                          ExamName : '',
                          examDate : ''
                        });
  const [assessmentCheckbox, setAssessmentCheckbox] = useState(false)
  
  const [questionDisplay, setQuestionDisplay] = useState([]);


  const [questionIndex,setQuestionIndex] = useState(0);
  const [examInstruction,setExamInstruction] = useState('');

  const [showIntructions, setShowIntructions] = useState(true);
  
  const [completedTest,setCompletedTest] = useState(false);
  
  const [isTimeout,setTimeout] = useState(false)
  
  const RadioButtonAnswerHandler = async(key,value,AssignmentId,data) => {
  const userCreds = await JSON.parse(localStorage.getItem('userCreds')) 
  const removeExistingKey = answerEditor.filter(k => k._id !== data._id);  
  try {
  const body = {
    studentId: userCreds.user._id,
    assignmentId:AssignmentId,
    questionId:data._id,
    correctAnswer:value,
    isCorrect:value === data.correctAnswer ? true : false,
    }
    const response = await postSingleAnswerByStudent(body);    
    if (response.success) {
      setAnswerEditor([...removeExistingKey, {
       key : key,
      value: value,
    ...data,
    
  }]);    
    } else {
      toast.error(response?.error)
    }
  } catch (err) {
        console.log(err,"READIOERROR");
  }
     
}

const getTimes = (date,startTime) =>{
  const dd = moment(date+" "+startTime)
  const momentDuration = moment.duration(dd.diff(moment()))
  return `
  ${parseInt(momentDuration.days())} Days
    ${parseInt(momentDuration.hours())} Hrs
    ${parseInt((momentDuration.minutes()))} Mins
    ${parseInt(momentDuration.seconds())} Secs
  `
}


  const AnswerEditorHandler = (key, value, data) => {
  const previousData = answerEditor.find(k => k.key === key);
  const removeExistingKey = answerEditor.filter(k => k.key !== key);  
    if (data !== undefined) {
      setAnswerEditor([...removeExistingKey, {
    key : key,
      value: value,
    ...data,
    
  }]);
    } else {
       setAnswerEditor([...removeExistingKey, {
    ...previousData,
    key : key,
    value: value,
  }]);
    }
   
  }
  


const AnswerCollectionHandler = (key,value,isDraft,toRemove) => {
    const removeDraftCollection = draftCollection.filter(
      item => item.key !== key
    )
    const removeResponseCollection = responseCollection.filter(
      item => item.key !== key
    )

    const response = {
      key : key,
      ...value
    }

    if(toRemove){
      setResponseCollection(removeResponseCollection)
      setDraftCollection(removeDraftCollection)
    }else{
      if(isDraft) {
        setDraftCollection([...removeDraftCollection,response])
        setResponseCollection(removeResponseCollection)
      }else{
        setDraftCollection(removeDraftCollection)
        setResponseCollection([...removeResponseCollection,response])
      }
    }
 
  }


  var entities = {
  'amp': '&',
  'apos': '\'',
  '#x27': '\'',
  '#x2F': '/',
  '#39': '\'',
  '#47': '/',
  'lt': '<',
  'gt': '>',
  'nbsp': ' ',
  'quot': '"'
}
function decodeHTMLEntities (text) {
  return text.replace(/&([^;]+);/gm, function (match, entity) {
    return entities[entity] || match
  })
}

const SubmitExamFunc = async(assignId,userId,value) => {

let bod = {
  assignmentId: assignId,
  studentId: userId,
  submitted: value
}

setCompletedTest(true);

const response = await ExamSubmissionByStudent(bod)

if(response.success === true){
  setCompletedTest(true);
}

}

  const getStudentPreviouslyAnsweredQuestionList = async(body) => {
    const response = await getStudentPreviouslyAnsweredQuestion(body)
    if (response) {
      if (response.success) {
        const data = response.data.map(x =>
        ({
        ...x,
       key : x.questionId,
          value: x.correctAnswer,
      _id :x.questionId,
        }))
        setAnswerEditor(data)
        console.log(response, "DSDSSDDDSDDSDSRRERE")
      
      }
    }
  }

  const studentansweredFunc = async(assignID,staffID) => {
    return 1
    // const body = {
    //   assignmentId : assignID,
    //   staffId : staffID,
    //   }
    //   const assignmentQuestion = await getAssignmentQuestion(body);
    //   if(assignmentQuestion.success) {
    //     let studData = {
    //       studentId: JSON.parse(localStorage.userCreds).user._id,
    //       assignmentId:assignID,
    //     }
    //     const response = await getStudentPreviouslyAnsweredQuestion(body)
    //     console.log(response,"QQQQQQQQQQQQQQQQQQQQQQQQQQ");
    //   }

  }
  const getAssignmentQuestions = async(data) => {
    try {
  const userCreds = await JSON.parse(localStorage.getItem('userCreds')) 

      const { _id } = data;
    const body = {
    assignmentId:_id,
    staffId :data.staffId,
    }
    const assignmentQuestion = await getAssignmentQuestion(body);
    if (assignmentQuestion) {
      if (assignmentQuestion.success) {
        setQuestionDisplay(assignmentQuestion.data)
        getStudentPreviouslyAnsweredQuestionList({
    studentId: userCreds.user._id,
    assignmentId:_id,
})
        setExamInstruction(data.instruction)
        setExamEndTime(data.examEndTime)
        setExamStartTime(data.examStartTime)
        try {
             const userCreds = await JSON.parse(localStorage.getItem('userCreds')) 
        const csvStructureData = assignmentQuestion.data.map(x => (
          [
            userCreds.userName,
            _id,
            x._id,
            decodeHTMLEntities(String(x.question).replace(/<(.|\n)*?>/g, '')),
            x.answerType === "RadioButton" ? 
            x.options.reduce((prev, next, index) => {
              if (index === 1) {
                return (`${index}) ${decodeHTMLEntities(String(prev.option).replace(/<(.|\n)*?>/g, ''))}\n ${index + 1}) ${
                  decodeHTMLEntities(String(next.option).replace(/<(.|\n)*?>/g, ''))
                  }`)
              }
              return (`${prev}\n ${index + 1} ${decodeHTMLEntities(String(next.option).replace(/<(.|\n)*?>/g, ''))}`)
            }): "",
            "",
          ]
        ))
    
          setCsvStructure([
            [
          [
        "StudentId",
        "AssignmentId",
        "questionId",
        "Question",
        "Options (Note : Kindly Type the Option Number in Answer Field ) ",
        "Answer",
          ],
          ...csvStructureData
            ]
          ])
        } catch (err) {
            console.log(err,"CSVSTRUCTURE")
        }
    
     setSelectedAssignment(_id)
      
      } else {
        toast.error(
          <Danger body={assignmentQuestion?.error} />
          )
    }
    }
    } catch (err) {
      console.log(err,"ASASSASASASCv")
    }
    
   
  }
const [examNotAvailable,setExamNotAvailable] = useState(false);

  const fetchData = async () => {
    setTableAssignmentDisplay([]);
    const userCreds = await JSON.parse(localStorage.getItem('userCreds')) 
    if (userCreds) {
      try {
        const body = {
          ageGroup : userCreds.user.ageGroup
        }
        const studentAssignment = await getAllStudentAssignments(body,userCreds.token);
        if (studentAssignment.success) {
          if(studentAssignment.data)
          {
          setTableAssignmentDisplay(studentAssignment.data)

            if(studentAssignment.data.length > 0){

              const tableData = studentAssignment.data[0]
              getAssignmentQuestions(tableData)
              setAssignmentName({
                subjectName: tableData.katbookSubjectName,
                ExamName : tableData.examName,
                examDate : tableData.examDate,

              })

              setExamNotAvailable(false)
            }else{
              setExamNotAvailable(true)
            }

          }
        } else {
          toast.error(
            <Danger body={studentAssignment.error} />
            );
        }
        
        }
    catch (error) {
        console.log(error,"USER_CREDENTIALS_RESPONSE")
    }

    }
    
  }

  useEffect(() => {
    // alert()
    if (localStorage.length > 0) {
    fetchData();
    } else {
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
  },[localStorage])



   const [timer, setTimer] = React.useState(3600)

  const settingTimer = React.useCallback(() => {
    console.log(timer,"TIMER")

      setTimer((timer) =>{
        if (timer == 0) {
          if (completedTest) {
          } else {
            setCompletedTest(true)
            // setTimeout(true)
          }
      return 0
      } else {
        return timer - 1
      }
      });
      
    
   
  })

  const [examEndTime, setExamEndTime] = useState('');
  const [examStartTime, setExamStartTime] = useState('');

  const Gettimer = (examStartTime,examEndTime) => {
  var currentTime = moment().format('LTS');
  var startTime =  moment(currentTime.toString(), 'HH:mm:ss a');
  var endTime = moment(examEndTime, 'HH:mm:ss a');
  // calculate total duration
  var duration = moment.duration(endTime.diff(startTime));
  // duration in hours
  var hours = parseInt(duration.asHours());
  // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60;
    console.log((parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes()) % 60) < 0,"HAOHSHASSH")
  if (hours < 0 || minutes < 0) {
    setCompletedTest(true);      
    } else {
    setTimer(((minutes * 60) + (hours * 3600))+60);      
    }
  }

  const logoutfunc = async () => {
    const goToHomePage = () => navigate("/login");
    goToHomePage();
  
  }

  
  return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}

{(selectedAssignment == "") &&
      <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
        personName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.authorizedPerson : JSON.parse(localStorage.userCreds).user.fullName : ""}
        ShowNotifications={ShowNotifications}
        userName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userName : ""}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        userType = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType : ""}
        userData = {localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""}
      />
      </div>
}
      {/* Header Section Ends */}


      <div 
      
        style={{
          marginTop:selectedAssignment == "" ? "100px" : "auto"
        }}
        // className={selectedAssignment == "" ? "Dash_home_QWI" : ""}
      >
        <div
          className={`myattendcard2w ${selectedAssignment == "" ? "p-3" : "paddingTop-10"}`}
          style={{
            height:selectedAssignment == "" ? window.innerHeight - 117 + "px" : "100vh",
            overflowY: "auto",
          }}
        >
          {/* <QuestionView condition={selectedAssignment == "" ?true :false } window={window}> */}
            
          {/* Assessment Table Starts */}
{selectedAssignment == "" 
&& 
<div
style={{
            height:selectedAssignment == "" ? window.innerHeight - 250 + "px" : "100vh",
            overflowY: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
> 

<h1>
{examNotAvailable ? "Exam not available" : " Please wait ..." }
</h1>
          {/* <KHeader
          style={{
            justifyContent: "space-between"
          }}
            frontElement={
              selectedAssignment !== ""
              &&
              <div
                  style={{
                    marginTop: "26px",
                    cursor : "pointer"
                }}
                onClick={() => {setSelectedAssignment("")}}
                >
                <IoIosArrowBack size={25}  />
          </div>}
            title={`Exams - ${moment().format("DD MMM YYYY")}`}
            containerClassName={`marginTop-20 justify-content-front`}
            LastElement={<div></div>}
          /> */}
          {/* <div className="marginLeft-20 marginRight-20 paddingLeft-10">
            <table 
                
                style={{
                    borderCollapse : "separate",
                    borderSpacing : "0px 5px"
                }}
            className="w-100 StudentAssignment__table">


              <tr
                style={{
                  border: "1px solid #E6ECF1",
                }}
              >
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"S.No"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Subject"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Name"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam By Teacher"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Total Marks"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Date"}
                    isRequired={false}
                  />
                    </th>
                      <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Exam Time"}
                    isRequired={false}
                  />
                  </th>
              </tr>

              {
                tableAssignmentDisplay.length > 0 ?
                tableAssignmentDisplay.map((tableData, index) => (
                    <tr 
                      onClick={() => {
                        getAssignmentQuestions(tableData)
                        setAssignmentName({
                          subjectName: tableData.katbookSubjectName,
                          ExamName : tableData.examName,
                          examDate : tableData.examDate,

                        })
                      }}
                className="py-3 StudentAssignment__tableElements"
                style={{
                  border: "1px solid #E6ECF1",
                      }}
                      
                 
                  >
                    <td className="p-2 StudentAssignment__tableStart">
                      {index + 1}
                    </td>
                    <td className="p-2 StudentAssignment__tableStart">
                      {tableData.katbookSubjectName}
                    </td>
                    <td className="p-2">{tableData.examName}</td>
                    <td className="p-2">{tableData?.staffName ? tableData?.staffName : ""}</td>
                      <td className="p-2">{tableData.totalMarks}</td>
                      <td className="p-2">
                        {
                    (tableData.examDate === null || 
                    tableData.examDate === undefined) ? "Not Assigned" : moment(tableData.examDate).format("DD/MMM/YYYY")
                    }
                       </td>
                    <td className="p-2 StudentAssignment__tableEnd">{
                    (tableData.examStartTime === null || 
                    tableData.examStartTime === undefined) ? "Not Assigned" : `${moment(tableData.examDate+" "+tableData.examStartTime).format("hh:mm A")} - ${moment(tableData.examDate+" "+tableData.examEndTime).format("hh:mm A")}`
                    }</td>
                   
                </tr>
                ))
                :
                <tr>
                  <td colspan="10">Exam not available</td>
                </tr>
              }

            </table>
              </div> */}
                 
                 </div>}
 {/* Assessment Table Ends */}

          {
            selectedAssignment !== "" &&
            <Fragment>
                  <Fragment>
            {
              selectedAssignment !== "" && showIntructions ?
                <div className="p-3 d-flex flex-column h-100 justify-content-between">
                        <div style={{
                          fontSize: "18px",
                          fontFamily: "Inter"
                        }}>
                          {/* {parse(examInstruction)} */}
                          <h4><u>General Instructions :</u></h4>
                        <ol>
                          <li className={"marginTop-10"}>Total Duration of Exam is {"60 Minutes"}</li>
                          <li className={"marginTop-10"}>The Clock will be set at the Server.the countdown timer in the top right corner of screen will display the remaining time available for you to complete the examination . when
the timer reaches zero , the examination will end by itself . you will not be required to end or submit your examination .
                          </li>
                          <li className={"marginTop-10"}>The Question Palette displayed on the left side of Screen will show the status of each  question
                            using one of the following symbols &nbsp;:
                            <br />
                            <div className="d-flex flex-row align-items-center">
                          <div
                          className="d-flex justify-content-center align-items-center"
                            
                             style={{
                          backgroundColor: "white",
                          color:"rgb(38 132 255)",
                          border: "1px solid rgb(38 132 255)",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                          margin: "10px",
                          }}
                            
                            >
                              1
                              </div>
                              <div>You have not answered/visited the question yet</div>
                            </div>
                           
   <div className="d-flex flex-row align-items-center">
                          <div
                          className="d-flex justify-content-center align-items-center"
                            
                             style={{
                             backgroundColor:  "green" ,
                          color: "white" ,
                          border: "1px solid #d8d8d8",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                          margin: "10px",
                          }}
                            
                            >
                             2
                              </div>
                              <div>You have answered the question </div>
                            </div>

                      
                          </li>

                          </ol>
                        </div>
                        <div className="border-d8d8d8 p-3 mb-5"
                          style={{
                          fontFamily: "Inter"
                        }}>
                          <span>
                            <input
                              onClick={() => {
                                setAssessmentCheckbox(!assessmentCheckbox)
                              }}
                              checked={assessmentCheckbox}
                              type="checkbox" />&nbsp;&nbsp;
                          </span>I have read and Understood the Instructions. All computer hardware alloted to me are in proper working condition.
                          I declare that I am not in possession of /not wearing/not carrying any prohibited gadget like mobile phones
                          bluetooth devices etc. /any prohibited material with me into the Examination Hall. I agree that in case of not adhering 
                          to the instructions . I shall be liable to be debarred from this Test and/or to disciplinary action, which may include 
                          ban from future Tests/Examination
                          <div className="d-flex justify-content-end pt-2">
                            <KButton
                              btnColor={
                                  parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) > 0
                                || (parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) > 0
                                  ? "rgb(102, 102, 102)"
                                  :
                                (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) < 0
                                || (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) < 0)
                            ? "rgb(102, 102, 102)"
                                :
                                "green"}
                              className={"ExamBtn"}
                              onClick={() => {
                                setShowIntructions(false);
                                Gettimer(examStartTime,examEndTime)
                              }}
                              isDisabled={!assessmentCheckbox
                                || parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) > 0
                                || (parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) > 0
                                  ? true
                                  :
                                (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) < 0
                                || (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) < 0)
                            ? true
                                :
                                false
                              }
                              label={
                                parseInt(moment(moment(assignmentName.examDate).format('YYYY-MM-DD')).diff(moment().format('YYYY-MM-DD'), 'days')) > 0 ?
                                getTimes(assignmentName.examDate,examStartTime)
                                : moment().format('YYYY-MM-DD') == assignmentName.examDate &&
                                  parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) > 0
                                || (parseInt(moment.duration(moment(examStartTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) > 0
                                  ? getTimes(assignmentName.examDate,examStartTime)
                                  :
                                (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asHours()) < 0
                                || (parseInt(moment.duration(moment(examEndTime, 'HH:mm:ss a').diff(moment(moment().format('LTS').toString(), 'HH:mm:ss a'))).asMinutes() + 1) % 60) < 0)
                            ? "Exam has been ended"
                                :
                                "Start"}
                  />
                          </div>
                  </div>
                 
                </div>
                
                      : completedTest ?
                        isTimeout ? 
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center"> 
                            <div className="d-flex flex-column">
                              <div>Timeout</div>
                              <div>
                                  <KButton
                          onClick={() => {
                            setShowIntructions(true);
                            setCompletedTest(false);
                            setTimeout(false);
                            setSelectedAssignment('');
                            setAssignmentName({
                                subjectName: '',
                                ExamName : '',
                                examDate : ''
                              })
                            setQuestionIndex(0);
                            setDraftCollection([]);
                            setResponseCollection([]);
                            setQuestionDisplay([]);
                            setAnswerEditor([]);
                            fetchData();
                          }}
                          label={"Continue"}
                        />
                              </div>
                            </div>   
                        </div>
                        :
                        <div className="d-flex w-100 h-100 justify-content-center align-items-center paddingBottom-50">
                            <div>
                              <div className="d-flex w-100 h-100 justify-content-center align-items-center" >
                                <img src={CompletedTick}
                                width="150" height="150" alt="finish test"
                                />
                              </div>
                              <div className="d-flex flex-column align-items-center">
                                <div className="h4 pt-2" style={{ textAlign: 'center', lineHeight: '40px'}}>
                                  Dear {JSON.parse(localStorage.userCreds)?.user.fullName}, <br/>Your answers has been submitted successfully
                                </div>
                                   <KButton
                          onClick={() => {
                            setShowIntructions(true);
                            setCompletedTest(false);
                            setSelectedAssignment('');
                            setAssignmentName({
                          subjectName: '',
                          ExamName : '',
                          examDate : ''
                        })
                            setQuestionIndex(0);
                            setDraftCollection([]);
                            setResponseCollection([]);
                            setQuestionDisplay([]);
                            setAnswerEditor([]);
                            // fetchData();
                            logoutfunc();
                          }}
                          className="px-3 py-2 marginTop-10"
                          label={"Back to ISKCON"}
                          style={{
                            width :'fit-content'
                          }}
                        />
                              </div>
                        </div>
                           
                     
                </div> :
              <div>
                {/* Cringe Header */}

                 <KHeader
          style={{
            justifyContent: "space-between"
                            }}
                            frontElement={
                              <div className="d-flex flex-column">
                                <div className="StudentAssignment__exam-title">{assignmentName?.ExamName }</div>
                                <div>{assignmentName?.subjectName }</div>
              </div>
            }
          
            containerClassName={`justify-content-front align-items-center`}
      
                  LastElement={
                    <div className="d-flex flex-row align-items-center">
  <Timer
                      
                    
                        countRef={counterRef}
                        timer={timer} settingTimer={settingTimer}
                    />
    <KButton 
               btnColor={"green"}
                style={{
                  height : "fit-content"
                }}
                isDisabled={
                  questionDisplay.length > 0 && answerEditor.length  === questionDisplay.length ? false : true
                }
                        onClick={() => {
                          SubmitExamFunc(selectedAssignment,JSON.parse(localStorage.userCreds).user._id,true)
                         // postAnswer(selectedAssignment)
                }}
                className="d-flex flex-row justify-content-center align-items-center px-4 py-2 mr-2"
                  label="Submit"
                />

                    </div>
                  
            }
          />
       
 {/* Download Upload Section Starts */}

                  <div className="d-flex flex-row h-100 border-top-d8d8d8" >
                  <div className="col-3 w-100 pt-3">
                    <div className="d-flex flex-row" 
                      style={{
                      flexWrap : "wrap",
                    }}
                    >
                      {
                        questionDisplay.map((x, i) => (
                          <div
                            onClick={() =>{setQuestionIndex(i)}}
                          className="d-flex justify-content-center align-items-center"
                            style={{
                          backgroundColor: answerEditor.filter(m => m._id == x._id).length > 0 ? "green" : "white",
                          color:answerEditor.filter(m => m._id == x._id).length > 0 ? "white" :questionIndex === i ? "#2684ff" :"black",
                          border: `1px solid ${(questionIndex === i && (!(answerEditor.filter(m => m._id == x._id).length > 0)))? "#2684ff" : "#d8d8d8"}`,
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                          margin: "3%",
                          cursor : "pointer"
                          }}>
                            <div>
                              {i + 1}
                            </div></div>
                        ))
                      }
</div>
                    </div>
                  <div className="col-9 pt-3" style={{
                    height: (window.innerHeight - 150) + "px",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    display: "flex",
                    borderLeft: "1px solid #d8d8d8"
                  }}>
                    <div>
{
                      questionDisplay.filter((quesData, indexQues) => {
                        
                        if (indexQues == questionIndex) {
                          return quesData
                        } else {
                          return null;
                        }
                      })
                        .map((data, index) => (
    <Fragment key={index}>   
{/* Question Section Start */}
<div className={`d-flex flex-row w-100 ${index === 0 ? "" : ""} ${isBigScreen ? "" : ""}`}>

            <div className="d-flex flex-row align-items-center py-2">
              <div className="d-flex flex-row ">
        <b>{questionIndex + 1} : </b>
              &nbsp;{parse(data.question)}
              </div>
            </div>
      
        </div>
{/* Question Section End */}
{
  // answerEditor.filter(item => item.key === index).length > 0 && 
  (<Fragment>
{/* Answer Section Start */}
<div className={`${isBigScreen ? "paddingLeft-10" : ""}`}>
          
          <div className="col-8">
       
             <Fragment>
          
                    <Fragment>
                      {
                        //                   answerEditor.find(item => item.key === questionIndex)?.answerType === "TextArea" || answerEditor.find(item => item.key === questionIndex)?.answerType === "TextBox" ?
                        // <KEditor
                        //   height={500}
                        //   onChange={value => {
                        //     AnswerEditorHandler(questionIndex,value)
                        //   }}
                        //   value={
                        //     answerEditor.find(item => item.key === questionIndex)?.value
                        //       ? answerEditor.find(item => item.key === questionIndex)?.value
                        //       : ''
                        //   }
                        // />
                        //                     :
                    data?.answerType === "RadioButton" &&
                        <Fragment>
                            {data.options.map((option, i) => (
                              <Fragment key={i}> 
                                <div className="d-flex flex-row">
                              <div className="pr-2">
                                  <input type="radio"
                                      name={data._id}
                                      checked={answerEditor.find(item => item._id == data._id)?.value
                                        ? answerEditor.find(item => item._id == data._id)?.value == option.option ?
                                          true : false : false}
                              onChange={(e) => { 
                                RadioButtonAnswerHandler(data._id,option.option,selectedAssignment,data)
                              }}
                                    />
                                  </div>
                                  <div>
                                    <span>{parse(option.option)}</span>
                                  </div>
                                </div>
                             
                                </Fragment>
                            ))
                           
                                              }
                                              
                        </Fragment>
            }
             </Fragment>

              </Fragment>
          
          </div>
     
          </div>
  {/* Answer Section End */}
  </Fragment>
  )
}


    </Fragment>
  ))
                      }
                    </div>
                    <div>
 <div className="d-flex flex-row ">
                  <div></div>
                  <div className="d-flex flex-row " >
 <div className="px-3 py-2 mr-2"
                            onClick={() => {
                              if (questionIndex === 0) {
     
                              } else {
                                 setQuestionIndex(questionIndex-1)
                              }
 }}
                            style={{
                              cursor: questionIndex === 0 ? "not-allowed" : "pointer",
                              opacity:questionIndex === 0 ? "0.5" : 1
                            }}>
                              <BsArrowLeft />&nbsp;Previous</div>
                          
                          <div className="px-3 py-2 mr-2"
                            onClick={() => {
                              if (questionIndex + 1 === questionDisplay.length) {
                                
                              } else {
                                setQuestionIndex(questionIndex+1)
                              }
                              }}
                            style={{
                              cursor:questionIndex+1 === questionDisplay.length ? "not-allowed" : "pointer",
                              opacity:questionIndex+1 === questionDisplay.length ? "0.5" : 1
                            }}>Next&nbsp;<BsArrowRight /></div>
                  </div>
                 
</div>
                    </div>


                    </div>
</div>


               
            </div> }
</Fragment>


        </Fragment>    
}
          
        
{/* </QuestionView> */}
        </div>
      </div>
{/* Footer Section Starts */}
      <Footer
        // openForm={openForm}
        // shoq={shoq}
        // closeForm={closeForm}
        // KpostIframe={KpostIframe}
      />
  {/* Footer Section Ends */}    

    </div>
  );
};

export default StudentAssignment;
