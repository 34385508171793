import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import { CCol, CModal, CModalBody } from "@coreui/react";
import { toast, ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  renderMatches,
} from "react-router-dom";
import { Authcontext } from "../src/views/components/Context/Context";
import {
  getDynamicTimetable,
  getUnitSessionNameFromtable,
  kampusJoinMeeting,
  kampusStartMeeting,
  kampusInfoMeeting,
  kampusStopMeeting,
} from "../src/services/Apiservices";
import Home from "./views/components/Home";
import Dashboard from "./views/components/Dashboard";
import SDashboard from "./views/components/StudentDashboard";
import TDashboard from "./views/components/TDashboard";
import Timetable from "./views/components/Timetable";
import BookView from "./views/components/BookView";
import BookViewCorporate from "./views/components/BookViewCorporate";
import AddStudent from "./views/components/admin/student/AddStudent";
import AddStaff from "./views/components/admin/staff/AddStaff";
import ReactGA from "react-ga";
import Download from "./views/components/Download";
import {
  getUserProfile,
  KampusLogin,
  getKampusStudentLogin,
  deleteKampusStudentLogin,
  getAllLog,
} from "./services/Apiservices";
import GlobalContext from "./services/GlobalContext";

import KSMACC from "./views/components/Ksmacc";
import Assignment from "./views/components/Assignment";
import CreateAssignment from "./views/components/CreateAssignment";
import AssignmentList from "./views/components/AssignmentList";
import CreateQuestion from "./views/components/CreateQuestion";
import PostQuestion from "./views/components/PostQuestion";
import StudentAssignment from "./views/components/StudentAssignment";
import ViewAssignment from "./views/components/ViewAssignment";
import PostAssignment from "./views/components/PostAssignment";
import Instructions from "./views/components/Instructions";
import Login from "./views/components/Login";
import Logout from "./views/components/Logout";
import Attendance from "./views/components/Attendance";
import "./App.css";
import "./views/components/Menu.css";
import "./views/components/style.css";
import "./views/components/response.css";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { compareAsc, format } from "date-fns";
import { GrClose } from "react-icons/gr";
import Img_Meet from "../src/Assets/Img_Meet.png";
import { MdVideocam } from "react-icons/md";

import Danger from "./views/components/Toastify/Danger";
import Warning from "./views/components/Toastify/Warning";

// import css Video Player for Iskon

import "../node_modules/video-react/dist/video-react.css";
// {/* <link rel="stylesheet" href="/css/video-react.css" /> */}

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

function App() {
  try {
    document.addEventListener("message", (message) => {
      alert("MESSAGE EVENT", message);
    });
  } catch (err) {
    console.log(err, "MESSAGEEVENTLISTENER");
  }

  React.useEffect(() => {
    try {
      const TRACKING_ID = "UA-199927572-1"; // OUR_TRACKING_ID
      ReactGA.initialize(TRACKING_ID);
    } catch (err) {
      console.error(err);
    }
    // console.log(localStorage,"LOCAL STORAGE");
    if (localStorage.length > 0 && localStorage.userCreds !== undefined) {
      // console.log(localStorage.userCreds)
      try {
        let retrData = JSON.parse(localStorage.userCreds);

        setuserCredsvalues(retrData);

        // console.log(userCredsvalues,"YYYYYYYYYYYYYYYYYYYYYYYYYY");
        if (retrData) {
          const decodedJwt = parseJwt(retrData.token);

          if (decodedJwt.exp * 1000 < Date.now()) {
            // alert();
            console.log("logout", "JWT SUCCESS");
          }
          // dispatch({type:"LOGIN",payload :retrData})
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  // const [state, dispatch] = React.useReducer(reducer, initialState);
  // const { state, dispatch } = useContext(Authcontext);
  const [userCredsvalues, setuserCredsvalues] = useState([]);

  // const navigate = useNavigate();

  const [isloading, setIsloading] = useState(false);

  const [conferenceData, setconferenceData] = useState("");
  const [secondvalues, setsecondvalues] = useState("");
  const [visible, setVisible] = useState(false);
  const [timerData, settimerData] = useState("");
  const countRef = React.useRef(null);

  // Footer Functions
  const [shoq, setShoq] = useState(true);

  const [KpostIframe, setKpostIframe] = useState(false);

  const closeForm = () => {
    setKpostIframe(false);
    setShoq(true);
  };

  const openForm = () => {
    // alert();
    setShoq(false);
    setKpostIframe(true);
  };

  // Header Functions
  const [showProfile, setShowprofile] = useState(false);
  const [showNotification, setNotification] = useState(false);
  const falseShowProfile = React.useCallback(() => {
    setShowprofile(false);
  });
  const ShowNotification = React.useCallback(() => {
    setNotification(!showNotification);
    falseShowProfile();
  });
  const falseShowNotification = React.useCallback(() => {
    setNotification(false);
  });
  const trueShowNotification = React.useCallback(() => {
    setNotification(true);
  });
  const notofShowProfile = React.useCallback(() => {
    setShowprofile(!showProfile);
    falseShowNotification();
  });
  const disableOnOuterTouch = () => {
    if (showProfile) {
      falseShowProfile();
    }
    if (showNotification) {
      falseShowNotification();
    }
  };

  const tableBgColors = [
    {
      color: "#9B36EA",
    },
    {
      color: "#06C270",
    },
    {
      color: "#FC5F37",
    },
    {
      color: "#3377FF",
    },
    {
      color: "#FF3E84",
    },
    {
      color: "#F78E13",
    },
    {
      color: "#dd3aff",
    },
    {
      color: "#ba4221",
    },
  ];

  const CuttentDateTime = new Date().toLocaleString("en-US", {
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  });

  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };

  const timer = (values) => {
    var seconds = values;
    settimerData("");
    clearInterval();
    // console.log(seconds,"SECONDSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
    const starttimer = (countdownTimer) => {
      var days = Math.floor(seconds / 24 / 60 / 60);
      var hoursLeft = Math.floor(seconds - days * 86400);
      var hours = Math.floor(hoursLeft / 3600);
      var minutesLeft = Math.floor(hoursLeft - hours * 3600);
      var minutes = Math.floor(minutesLeft / 60);
      var remainingSeconds = seconds % 60;
      const pad = (n) => {
        return n < 10 ? "0" + n : n;
      };

      if (remainingSeconds > 0) {
        // console.log(remainingSeconds,"DAYSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
        // console.log(days,"DAYSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
        settimerData(
          days +
            " Day " +
            pad(hours) +
            " Hr " +
            pad(minutes) +
            " Min " +
            pad(remainingSeconds) +
            " Sec"
        );
        document.getElementById("countdown").innerHTML =
          days +
          " Day " +
          pad(hours) +
          " Hr " +
          pad(minutes) +
          " Min " +
          Math.trunc(pad(remainingSeconds)) +
          " Sec";
      } else {
        settimerData("Completed");
        document.getElementById("countdown").innerHTML = "Meeting Available";
      }

      if (seconds === 0 || seconds === "NaN") {
        clearInterval();
        document.getElementById("countdown").innerHTML = "Completed";
        settimerData("Completed");
      } else {
        seconds--;
      }
      // 1 Day 16Hr 23Min 21 Sec
    };

    countRef.current = setInterval(() => {
      starttimer();
    }, 1000);

    // clearInterval(countdownTimer);

    // var countdownTimer = setInterval(() => {
    //   starttimer()
    // }, 1000);
  };

  const VideoConfFunc = async (
    SubjectDate,
    SubjectName,
    StartTime,
    EndTime,
    StaffName,
    StaffDesig,
    UnitID,
    sessionID,
    staffID,
    tableID,
    subjectID,
    classId
  ) => {
    let seconds, GetextactSubjectDate, currentDate;
    seconds = "";
    GetextactSubjectDate = "";
    currentDate = "";
    let getUnitVales = {
      uId: UnitID,
      sId: sessionID,
    };

    let infomeetStatus = {
      classId: classId,
      subjectId: subjectID,
      timeTableId: tableID,
    };

    let responseInfoMeeting = await kampusInfoMeeting(infomeetStatus);

    // alert();

    console.log(userCredsvalues, "TTTTTTTTTTTTTTTTTTTTTTTT");

    let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);

    // GetextactSubjectDate = format(new Date(SubjectDate), 'yyyy/MM/dd');
    // alert(1)
    // GetextactSubjectDate = SubjectDate.split(" ")[1] ? SubjectDate.split(" ")[1].split("/")[1] : SubjectDate.split("/")[1]+" "+SubjectDate.split(" ")[1] ? SubjectDate.split(" ")[1].split("/")[0] : SubjectDate.split("/")[0]+", "+SubjectDate.split(" ")[1] ? SubjectDate.split(" ")[1].split("/")[2] : SubjectDate.split("/")[2];
    // GetextactSubjectDate = SubjectDate.split(" ")[1].split("/")[1]+" "+SubjectDate.split(" ")[1].split("/")[0]+", "+SubjectDate.split(" ")[1].split("/")[2];
    GetextactSubjectDate =
      SubjectDate.split("/")[1] +
      " " +
      SubjectDate.split("/")[0] +
      ", " +
      SubjectDate.split("/")[2];
    // alert(23);
    currentDate = new Date();
    // alert(45);
    // console.log(currentDate,"OPENNNNNNNNNNNNNNNNNNN");
    // console.log(GetextactSubjectDate,"OPENNNNNNNNNNNNNNNNNNN");
    // console.log(new Date(),"OPENNNNNNNNNNNNNNNNNNN");

    // console.log(((+aq - +bq) / 1000),"TIMER VALUES");

    var aq = new Date(`${GetextactSubjectDate} ${StartTime}`),
      // bq = new Date(currentDate);
      bq = currentDate;
    // console.log(((+aq - +bq) / 1000),"TIMER VALUES");
    // var upgradeTime = ((+aq - +bq) / 1000);
    var upgradeTime = (+aq - +bq) / 1000;

    seconds = upgradeTime;

    // var countdownTimer = setInterval(timer(), 1000);

    let conferDataset = {
      subjectName: SubjectName,
      SubjectDate: new Date(SubjectDate).toLocaleDateString("en-us", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
      MeetingDate: SubjectDate.replace("/", "-"),
      StartTime: StartTime,
      EndTime: EndTime,
      StaffName: StaffName,
      StaffDesig: StaffDesig,
      unitName: getresultUnits.unit ? getresultUnits.unit.variableName : "",
      sessionName: getresultUnits.session
        ? getresultUnits.session.variableName
        : "",
      duration: seconds,
      staffID: staffID,
      tableID: tableID,
      subjectID: subjectID,
      classID: classId,
      meetingInfoStatus: responseInfoMeeting.success,
    };

    // console.log(conferDataset,"CONFERENCE DATAAAAAAAAAAAAAAAAA");

    setsecondvalues(seconds);
    setconferenceData(conferDataset);
    timer(seconds);
    // setTimeout(() => {
    //   setVisible(!visible);
    // }, 1000);
    setVisible(!visible);
    document.querySelector("body").style.overflow = "hidden";
  };

  const openBookview = async (unitID, sessionID, subjectID) => {
    let getUnitVales = {
      uId: unitID,
      sId: sessionID,
    };

    let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
    // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");
    // console.log(getresultUnits,"BOOOOOOOOOOOOOOOOOOOOOO");
    // let variName = await getresultUnits.unit.variableName;
    // setData(getresultUnits)
    if (getresultUnits) {
      window.open(
        `https://s3.katbook.com/#/kampus/BookView/${
          getresultUnits.unit.parentVaribaleId
        }/${getresultUnits.unit.typeOfBookId}/{"sessionCode":"${
          getresultUnits.session.sessionCode
        }","_id":"${getresultUnits.session._id}","variableName":"${
          getresultUnits.session.variableName
        }","parentVaribaleId":{"_id":"${
          getresultUnits.unit._id
        }","variableName":"${getresultUnits.unit.variableName}","username":"${
          localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
        }","code":"${
          localStorage.length > 0 && JSON.parse(localStorage.userCreds).password
        }"}}/${
          localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID
        }/${subjectID}/${
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID
        }/${
          localStorage.length > 0 && JSON.parse(localStorage.userCreds).mediumId
        }`
      );
    }

    // setSubData(getresultUnits.session.variableName)
  };

  const JoinStuKampudMeeting = async (
    staffID,
    subjectID,
    tableID,
    classID,
    unitName,
    sessionName,
    subjectName,
    staffName,
    staffDesign,
    MeetingDate,
    StartTime,
    EndTime
  ) => {
    // alert(staffID+"=="+subjectID +"=="+tableID)
    setIsloading(true);
    if (
      localStorage.length > 0 &&
      JSON.parse(localStorage.userCreds).userType === "Teaching"
    ) {
      let meetparams = {
        // classId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
        isAdmin: true,
        classId: classID,
        subjectId: subjectID,
        refInstId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID,
        meetingStatus: "start",
        KPostId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName,
        staffId: staffID,
        staffName:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).adminName,
        timetableId: tableID,
        staffDesignation:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).designation,
        className:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).designation +
            " - " +
            subjectName +
            " - " +
            unitName +
            " - " +
            sessionName,
        sessionDate: MeetingDate.replace("/", "-"),
        sessionTime: StartTime + "-" + EndTime,
      };

      let responseMeetings = await kampusStartMeeting(meetparams);

      if (responseMeetings.success === true) {
        window.open(responseMeetings.meetURL, "_blank");

        clearInterval(countRef.current);
        setIsloading(false);
        setVisible(false);
        document.querySelector("body").style.overflow = "auto";
      }
    } else {
      let meetparams = {
        // classId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
        classId: classID,
        subjectId: subjectID,
        refInstId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).refInstID,
        meetingStatus: "start",
        kpostId:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).userName,
        staffId: staffID,
        fullName:
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).adminName,
        timeTableId: tableID,
      };

      let responseMeetings = await kampusJoinMeeting(meetparams);

      if (responseMeetings.success === true) {
        window.open(responseMeetings.meetingURL, "_blank");

        clearInterval(countRef.current);
        setIsloading(false);
        setVisible(false);
        document.querySelector("body").style.overflow = "auto";
      }
    }

    // if(responseMeetings.success === true){
    //   alert()
    //   // navigate("/timetable");
    // }
    // alert(responseMeetings.meetingUR)
    // // debugger
    // //window.open("",'_blank');
    // let v=responseMeetings.meetingURL;

    // console.log(v)

    // {
    //   "subjectId": 598,
    //   "classId": 227,
    //   "refInstId": 121,
    //   "meetingStatus": "start",
    //   "kpostId": "st12122005",
    //   "staffId": 172,
    //   "fullName": "Vishal K",
    //   "timeTableId": 2181
    // }

    //   let getUnitVales = {
    //     uId: unitID,
    //     sId: sessionID,
    // };
  };

  const StopKampusMeeting = async (subjectID, classID, tableID) => {
    let meetparams = {
      classId: classID,
      subjectId: subjectID,
      timeTableId: tableID,
    };

    let responseMeetings = await kampusStopMeeting(meetparams);

    if (responseMeetings.success === true) {
      clearInterval(countRef.current);
      setIsloading(false);
      setVisible(false);
      document.querySelector("body").style.overflow = "auto";
    }
  };

  console.log(userCredsvalues, "oooooooooooooo");
  // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", JSON.parse(localStorage.userCreds));

  return (
    <Authcontext.Provider
    // value={{
    //   state,
    //   dispatch,
    // }}
    >
      {/* <GlobalContext> */}
      <Router>
        <div>
          {/* { state.isAuthenticated ? ( */}
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/download" element={<Download />} />

            <Route
              path="/dashboard"
              element={
                <Dashboard
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  trueShowNotification={trueShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            {/* <Route path='/BookView' element={<BookView 
            showProfile={showProfile}
            falseShowProfile={falseShowProfile}
            notofShowProfile={notofShowProfile}
            ShowNotifications={ShowNotification}
            showNotification={showNotification}
            falseShowNotification={falseShowNotification}
            disableOnOuterTouch={disableOnOuterTouch}
            shoq={shoq}
            KpostIframe={KpostIframe}
            closeForm={closeForm}
            openForm={openForm}
            />} /> */}
            <Route
              path="/BookView"
              element={
                <BookViewCorporate
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/tdashboard"
              element={
                <TDashboard
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  trueShowNotification={trueShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/student/dashboard"
              element={
                <SDashboard
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  trueShowNotification={trueShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            {/* <Route path='/BookView' element={<BookView 
            showProfile={showProfile}
            falseShowProfile={falseShowProfile}
            notofShowProfile={notofShowProfile}
            ShowNotifications={ShowNotification}
            showNotification={showNotification}
            falseShowNotification={falseShowNotification}
            disableOnOuterTouch={disableOnOuterTouch}
            shoq={shoq}
            KpostIframe={KpostIframe}
            closeForm={closeForm}
            openForm={openForm}
            />} /> */}
            <Route
              path="/timetable"
              element={
                <Timetable
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/attendance"
              element={
                <Attendance
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/assignment/past"
              element={
                <Assignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/exam/student"
              element={
                <StudentAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/assignment/pending"
              element={
                <Assignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/assignment/create"
              element={
                <Assignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/CreateExam"
              element={
                <CreateAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/ViewExam"
              element={
                <AssignmentList
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/CreateQuestion"
              element={
                <CreateQuestion
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/PostQuestion"
              element={
                <PostQuestion
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/assignment/view"
              element={
                <ViewAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/assignment/post"
              element={
                <PostAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/ksmacc"
              element={
                <KSMACC
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            {/* ISCON ROUTES */}
            <Route
              path="/student/register"
              element={
                <AddStudent
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/staff/register"
              element={
                <AddStaff
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotification}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
          {/* ) : (
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/dashboard' element={<Logout />} />
            <Route path='/katbook' element={<Logout />} />
            <Route path='/timetable' element={<Logout />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
          </Routes>
        )
        } */}
        </div>
      </Router>
      <ToastContainer autoClose={3000} />
      {/* </GlobalContext> */}
      {localStorage.length > 0 && localStorage.userCreds !== undefined && (
        <CModal
          alignment="center"
          visible={visible}
          onClose={() => {
            clearInterval(countRef.current);
            setVisible(false);
            document.querySelector("body").style.overflow = "auto";
          }}
        >
          {/* <CModalBody> */}
          <div className={"Video_Call_Interface"}>
            <div>
              <div className={"D_Flex_Chapter"}>
                <div>
                  <span className={"h5_Eng"}>{conferenceData.subjectName}</span>
                </div>
                <div>
                  <GrClose
                    fontWeight={500}
                    size="22"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      clearInterval(countRef.current);
                      setVisible(false);
                      document.querySelector("body").style.overflow = "auto";
                    }}
                  />
                </div>
              </div>
              <div className={"D_Flex_Div"}>
                <div style={{ width: "60%" }}>
                  <span className={"P_Unit_S"}>{conferenceData.unitName}</span>
                </div>
                <div style={{ width: "50%" }}>
                  <span className={"Session_2"}>
                    <span className={"clarify_dot"}></span>
                    &nbsp; {conferenceData.sessionName}
                  </span>
                </div>
              </div>
            </div>

            <div className={"D_Flex_Time"}>
              <div>
                <span className={"Mon_Apr"}>{conferenceData.SubjectDate}</span>
              </div>
              <div>
                <span className={"Mon_Apr"}>
                  <span className={"clarify_dot"}></span>
                  {conferenceData.StartTime && (
                    <>
                      &nbsp; {tConvert(conferenceData.StartTime)} -{" "}
                      {tConvert(conferenceData.EndTime)}
                    </>
                  )}
                </span>
              </div>
            </div>

            <div className={"D_Flex_M_Profile"}>
              <div>
                <span className={"Meet_Font"}>Meeting Starts in:</span>
                <div className={"Meet_Time_Font"}>
                  {/* 1 Day 16Hr 23Min 21 Sec */}
                  <span id="countdown" class="timer"></span>
                </div>
              </div>
              <div className={"Img_Gap"}>
                <div>
                  <img src={Img_Meet} />
                </div>
                <div>
                  <span className={"Meet_Manoj_Font"}>
                    {conferenceData.StaffName}
                  </span>
                  <div className={"Meet_E_T_Font"}>
                    {conferenceData.StaffDesig}
                  </div>
                </div>
              </div>
            </div>

            <div className={"Button_Center"}>
              {timerData === "Completed" ? (
                <>
                  {isloading === true ? (
                    <>
                      <button
                        className={"Button meeting_disable"}
                        style={{ cursor: "no-drop" }}
                      >
                        <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                        <span className={"Button_J_M"}>
                          {localStorage.length > 0 &&
                          JSON.parse(localStorage.userCreds).userType ===
                            "Teaching"
                            ? "Loading ..."
                            : "Loading ..."}
                        </span>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        style={{
                          cursor:
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === false
                              ? "auto"
                              : "pointer",
                        }}
                        onClick={() => {
                          if (
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === true
                          ) {
                            JoinStuKampudMeeting(
                              conferenceData.staffID,
                              conferenceData.subjectID,
                              conferenceData.tableID,
                              conferenceData.classID,
                              conferenceData.unitName,
                              conferenceData.sessionName,
                              conferenceData.subjectName,
                              conferenceData.StaffName,
                              conferenceData.StaffDesign,
                              conferenceData.MeetingDate,
                              conferenceData.StartTime,
                              conferenceData.EndTime
                            );
                          } else if (
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Student" &&
                            conferenceData.meetingInfoStatus === false
                          ) {
                          } else {
                            JoinStuKampudMeeting(
                              conferenceData.staffID,
                              conferenceData.subjectID,
                              conferenceData.tableID,
                              conferenceData.classID,
                              conferenceData.unitName,
                              conferenceData.sessionName,
                              conferenceData.subjectName,
                              conferenceData.StaffName,
                              conferenceData.StaffDesign,
                              conferenceData.MeetingDate,
                              conferenceData.StartTime,
                              conferenceData.EndTime
                            );
                          }
                        }}
                        className={"Button meeting_avail"}
                      >
                        <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                        <span className={"Button_J_M"}>
                          {localStorage.length > 0 &&
                          JSON.parse(localStorage.userCreds).userType ===
                            "Teaching"
                            ? conferenceData.meetingInfoStatus === true
                              ? "Rejoin Meeting"
                              : "Start Meeting"
                            : conferenceData.meetingInfoStatus === true
                            ? "Join Meeting"
                            : "Please wait until your teacher join"}
                        </span>
                      </button>

                      {localStorage.length > 0 &&
                        JSON.parse(localStorage.userCreds).userType ===
                          "Teaching" &&
                        conferenceData.meetingInfoStatus === true && (
                          <button
                            onClick={() => {
                              StopKampusMeeting(
                                conferenceData.subjectID,
                                conferenceData.classID,
                                conferenceData.tableID
                              );
                            }}
                            className={"Button meeting_avail"}
                            style={{ background: "red", marginLeft: "2%" }}
                          >
                            <MdVideocam
                              size="30"
                              style={{ color: "#FFFFFF" }}
                            />
                            <span className={"Button_J_M"}>
                              {localStorage.length > 0 &&
                              JSON.parse(localStorage.userCreds).userType ===
                                "Teaching"
                                ? "End Meeting"
                                : ""}
                            </span>
                          </button>
                        )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    className={"Button meeting_disable"}
                    style={{ cursor: "no-drop" }}
                  >
                    <MdVideocam size="30" style={{ color: "#FFFFFF" }} />
                    <span className={"Button_J_M"}>
                      {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).userType === "Teaching"
                        ? "Start Meeting"
                        : "Join Meeting"}
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
          {/* </CModalBody> */}
        </CModal>
      )}
    </Authcontext.Provider>
  );
}

export default App;
