import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";
import { BiChevronDown } from "react-icons/bi";
import { FiBookOpen } from "react-icons/fi";
import { AiOutlineAppstore } from "react-icons/ai";
import { MdOutlineQuiz } from "react-icons/md";

const MenuDropdownItems = ({ items, depthLevel, userData, userId }) => {
  const location = useLocation();

  // console.log(userData.user.userName, "UUUUUUUUUUUUUUUUUUUUUU");

  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  return (
    <li
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={closeDropdown}
      className="menu-items"
      ref={ref}
    >
      {/* {items.submenu && items.url ? ( */}
      {items.submenu &&
      items.url &&
      userId !== undefined &&
      userId === "adisk22" ? (
        <>
          {/* <button 
    //    type="button" 
    //    aria-haspopup="menu"
    //    aria-expanded={dropdown ? "true" : "false"}
    //   onClick={() => setDropdown((prev) => !prev)}
       > */}
          {window.innerWidth < 960 && depthLevel === 0 ? (
            <>{items.title}</>
          ) : (
            <>
              {items.url === "#" && (
                <>
                  <MdOutlineQuiz className={"menu_icon"} size="20" />
                  &nbsp;
                </>
              )}
              <Link to={items.url} component={items.component}>
                {items.title}
              </Link>
            </>
          )}
          {depthLevel > 0 && window.innerWidth < 960 ? null : depthLevel > 0 &&
            window.innerWidth > 960 ? (
            <span>&raquo;</span>
          ) : (
            <span className="arrow" />
          )}
          {/* </button> */}
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <Link>{items.title}</Link>&nbsp;
          <BiChevronDown className={"menu_icon"} size="20" />
          <Dropdown
            submenus={items.submenu}
            dropdown={dropdown}
            depthLevel={depthLevel}
          />
        </>
      ) : (
        <>
          {items.url !== "#" && (
            <>
              {items.url === "/BookView" && (
                <>
                  <FiBookOpen className={"menu_icon"} size="20" />
                  &nbsp;
                </>
              )}
              {items.url === "/dashboard" && (
                <>
                  <AiOutlineAppstore className={"menu_icon"} size="20" />
                  &nbsp;
                </>
              )}
              {/* {items.url === "/CreateExam" && (
            <>
              <MdOutlineQuiz className={"menu_icon"} size="20" />
              &nbsp;
            </>
          )} */}
              <Link
                className={location.pathname === items.url ? "active" : ""}
                to={items.url}
                component={items.component}
              >
                {items.title}
              </Link>
            </>
          )}
        </>
      )}
    </li>
  );
};

export default MenuDropdownItems;
