import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import kpostLogo from "../../Assets/ksmaclogo.png";
import { CCol } from "@coreui/react";
import ksmaccL from "../../Assets/ksmaclogo.png";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import {
  GetAllStudent,
  GetAllProgressByStudent,
  fetchSUbjects,
  GetSessionCountbySubject,
  GetQuestionCountbySubject,
  GetAssessmentAnswerByStudent,
} from "../../services/Apiservices";

const LessonCoverage = ({
  userInfo,
  BookId,
  isTabScreen,
  BookSessionCount,
  QuestionCount,
}) => {
  const [ProgressData, setProgressData] = useState([]);
  const [UserSubject, setUserSubject] = useState("");
  const [Booksessioncount, setBooksessioncount] = useState("");
  const [Bookquestioncount, setBookquestioncount] = useState("");
  const [Progresssessioncount, setProgresssessioncount] = useState("");
  const [Progressquestioncount, setProgressquestioncount] = useState("");
  const [UserLastSession, setUserLastSession] = useState("");

  useEffect(() => {
    const getProgressInfor = async () => {
      let res_progress = await GetAllProgressByStudent(userInfo._id);

      if (res_progress.success === true) {
        setProgressData(res_progress.data);
        const firstPerValues = res_progress.data.filter(
          (items) => Number(items.percentage) === 100
        ).length;
        const secondPervalues =
          res_progress.data.filter((items) => Number(items.percentage) === 50)
            .length / 2;
        setProgresssessioncount(
          Number(firstPerValues) + Number(secondPervalues)
        );

        console.log(Progresssessioncount, "PROGRESS DATA");

        let res_session_count = await GetSessionCountbySubject(BookId);

        if (res_session_count.success === true) {
          setBooksessioncount(res_session_count.sessionCount);
        } else {
          setBooksessioncount(0);
        }
      } else {
        setProgresssessioncount(0);
        setBooksessioncount(0);
      }

      let allStuAnsReq = await GetAssessmentAnswerByStudent(userInfo._id);

      if (allStuAnsReq.success === true) {
        setProgressquestioncount(allStuAnsReq.value.length);

        let res_question_count = await GetQuestionCountbySubject(BookId);

        if (res_question_count.success === true) {
          setBookquestioncount(res_question_count.questionCount);
        } else {
          setBookquestioncount(0);
        }
      } else {
        setProgressquestioncount(0);
        setBookquestioncount(0);
      }
    };

    getProgressInfor();
  }, [userInfo]);

  return (
    <>
      <div className={`pt-0 ${isTabScreen ? "pl-0" : "pl-0"}`}>
        <div className="Col_Per_Name">
          <div className="PercentComplete">
            <div className="Percentage_Font">
              {Progresssessioncount && Booksessioncount
                ? Math.floor((Progresssessioncount / Booksessioncount) * 100) >
                  100
                  ? 100
                  : Math.floor((Progresssessioncount / Booksessioncount) * 100)
                : 0}
              %
            </div>
          </div>

          <div>
            <div className="Covered_Font">Lesson Coverage</div>
          </div>
        </div>
      </div>

      <div className={`pt-0 ${isTabScreen ? "pl-0" : "pl-0"}`}>
        <div className="Col_Per_Name">
          <div className="PercentComplete">
            <div className="Percentage_Font">
              {Progressquestioncount && Bookquestioncount
                ? Math.floor(
                    (Progressquestioncount / Bookquestioncount) * 100
                  ) > 100
                  ? 100
                  : Math.floor(
                      (Progressquestioncount / Bookquestioncount) * 100
                    )
                : 0}
              %
            </div>
          </div>

          <div>
            <div className="Covered_Font">Assessment Coverage</div>
          </div>
        </div>
      </div>

      <div className={`pt-0 ${isTabScreen ? "pl-0" : "pl-0"}`}>
        <div className="Col_Per_Name">
          <div className="PercentComplete">
            <div className="Percentage_Font">
              {Progresssessioncount &&
              Booksessioncount &&
              Progressquestioncount &&
              Bookquestioncount
                ? Math.floor(
                    (Number(
                      Math.floor(
                        (Progresssessioncount / Booksessioncount) * 100
                      ) > 100
                        ? 100
                        : Math.floor(
                            (Progresssessioncount / Booksessioncount) * 100
                          )
                    ) +
                      Number(
                        Math.floor(
                          (Progressquestioncount / Bookquestioncount) * 100
                        ) > 100
                          ? 100
                          : Math.floor(
                              (Progressquestioncount / Bookquestioncount) * 100
                            )
                      )) /
                      2
                  )
                : 0}
              %
            </div>
          </div>

          <div>
            <span className="Covered_Font">Overall</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonCoverage;
