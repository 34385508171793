import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Row, Col } from "reactstrap";
import useResponsive from "../../hooks/useResponsive";
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import parse from "html-react-parser";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import data from "../../../src/data.json";
import {
  fetchAllAssignments,
  fetchSUbjects,
  UpdatedExamByID,
} from "../../services/Apiservices";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import moment from "moment";

const AssignmentList = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
}) => {
  const { isBigScreen } = useResponsive();
  const [userscredsData, setuserscredsData] = useState([]);
  const [getAllExams, setgetAllExams] = useState([]);

  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState([]);
  const [singleExamType, setsingleExamType] = React.useState([]);
  const [AsstTitle, setAsstTitle] = useState("");
  const [AsstMarks, setAsstMarks] = useState("");
  const [AsstDate, setAsstDate] = useState("");
  const [ExamSTime, setExamSTime] = useState("");
  const [ExamETime, setExamETime] = useState("");
  const [InstructionsTxt, setInstructionsTxt] = React.useState("");
  const [updateBtn, setupdateBtn] = useState(false);
  const [updateViewEnable, setupdateViewEnable] = useState(false);
  const [assignmentId, setassignmentId] = useState("");

  let ExamType = [
    {
      label: "Mockup Exam",
      value: "mockup"
    },
    {
      label: "Final Exam",
      value: "final"
    }
  ];

  const CourseListFunc = async (userType) => {
    if (userType === "TEACHER") {
      let allquestres = await fetchSUbjects();
      if (allquestres.units.length > 0) {
        let dataOPtion = await allquestres.units.map((e) => {
          return {
            _id: e._id,
            age: e.age,
            bookName: e.book,
            value: e.age + " - " + e.book,
            label: e.age + " - " + e.book,
          };
        });

        setsubjectsData(dataOPtion);
      }
    } else {
      let allquestres = await fetchSUbjects();
      if (allquestres.units.length > 0) {
        let dataOPtion = await allquestres.units.map((e) => {
          return {
            _id: e._id,
            age: e.age,
            bookName: e.book,
            value: e.age + " - " + e.book,
            label: e.age + " - " + e.book,
          };
        });

        setsubjectsData(dataOPtion);
      }
    }
  };

  const ExamListFunc = async (userType) => {
    setupdateViewEnable(false);
    let result = await fetchAllAssignments();
    if (result.success) {
      setgetAllExams(result.data);
    } else {
      setgetAllExams([]);
    }
  };

  useEffect(() => {
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);
      setuserscredsData(retrData);
      console.log(retrData, "USERRRRRRRRRRRRRRRRRRRRRRRR");
      ExamListFunc();
      CourseListFunc(retrData.user.userTypeId.userType);
    }
  }, []);

  const UpdateAssignmentFunc = async () => {
    setupdateBtn(true);
    // alert()
    let result;
    let body = {
      katbookSubjectId: singleSubject._id,
      katbookSubjectName: singleSubject.bookName,
      ageGroup: singleSubject.age,
      type: singleExamType.value,
      examName: AsstTitle,
      totalMarks: AsstMarks,
      examDate: AsstDate,
      examStartTime: ExamSTime,
      examEndTime: ExamETime,
      instruction: InstructionsTxt,
    };
    console.log(body, "CREATE ASSIGNMENTSSSSSSSSSSSSSSSSS");
    try {
      result = await UpdatedExamByID(assignmentId, body);
      if (result.success) {
        EmptyAssigtCreateData();
        toast.success(<Success body={result.message} />);
      } else {
        toast.error(<Danger body={result.error} />);
      }
      setupdateBtn(false);
      ExamListFunc();
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeInstrusFuc = (e) => {
    console.log(e);
    setInstructionsTxt(e);
  };

  const EmptyAssigtCreateData = () => {
    // alert();
    setsingleSubject("");
    setsingleExamType("");
    setAsstTitle("");
    setAsstMarks("");
    setAsstDate("");
    setExamSTime("");
    setExamETime("");
    setInstructionsTxt("");
    setupdateViewEnable(false);
    setassignmentId("");
  };

  const updateFuc = async (data) => {
    console.log(data, "OOOOOOOOOOOOOOOOOOOOOOOO");
    if (data._id) {
      await setsingleSubject({
        age: data.ageGroup,
        bookName: data.katbookSubjectName,
        value: "Age " + data.ageGroup + " - " + data.katbookSubjectName,
        label: "Age " + data.ageGroup + " - " + data.katbookSubjectName,
        _id: data.katbookSubjectId,
      });
    }
    setsingleExamType({
      label: data.type === "mockup" ? "Mockup Exam" : "Final Exam",
      value: data.type === "mockup" ? "mockup" : "final",
    })
    setAsstTitle(data.examName);
    setAsstMarks(data.totalMarks);
    setAsstDate(data.examDate);
    setExamSTime(data.examStartTime);
    setExamETime(data.examEndTime);
    setInstructionsTxt(data.instruction);
    setassignmentId(data._id);
    setupdateViewEnable(true);
  };

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          // profileIcon={KpIcon}
          // username={state.adminName ? state.adminName : state.user}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          // institutionName={localStorage.length > 0 ? JSON.parse(localStorage.userCreds).user.userTypeId.userType == 'ADMIN' ? JSON.parse(localStorage.userCreds).user.institutionName.toUpperCase() : JSON.parse(localStorage.userCreds).user.institutionId.institutionName.toUpperCase() : ""}
          personName={
            localStorage.length > 0
              ? JSON.parse(localStorage.userCreds).user.userTypeId.userType ==
                "ADMIN"
                ? JSON.parse(localStorage.userCreds).user.authorizedPerson
                : JSON.parse(localStorage.userCreds).user.fullName
              : ""
          }
          ShowNotifications={ShowNotifications}
          userName={
            localStorage.length > 0
              ? JSON.parse(localStorage.userCreds).user.userName
              : ""
          }
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
          userType={
            localStorage.length > 0
              ? JSON.parse(localStorage.userCreds).user.userTypeId.userType
              : ""
          }
          userData={
            localStorage.length > 0 ? JSON.parse(localStorage.userCreds) : ""
          }
        />
      </div>
      <div className={"Dash_home_QWI"} style={{ overflow: "scroll"}}>
        <div
          className="myattendcard2w p-3"
          // style={{
          //   height: window.innerHeight - 117 + "px",
          //   overflowY: "auto",
          // }}
        >
          {!updateViewEnable && (
            <>
              <KHeader title="View Exams" containerClassName={``} />
              <div className="container-fluid px-5 py-3">
                {/* Create Assignment Component Starts With Responsive */}
                <div className="d-flex flex-column flex-md-row col-md-12">
                  <table
                    style={{
                      borderCollapse: "separate",
                      borderSpacing: "0px 5px",
                    }}
                    className="w-100 StudentAssignment__table"
                  >
                    <tr
                      style={{
                        border: "1px solid #E6ECF1",
                      }}
                    >
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"S.No"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Subject"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Age Group"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Exam Type"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Exam Name"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Total Marks"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Exam Date"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Exam Time"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Posted"}
                          isRequired={false}
                        />
                      </th>
                      <th className="p-2">
                        <KRequiredLabel
                          className={"mb-0"}
                          title={"Action"}
                          isRequired={false}
                        />
                      </th>
                    </tr>

                    {getAllExams.length > 0 ? (
                      getAllExams.map((tableData, index) => (
                        <tr
                          //   onClick={() => {
                          //     getAssignmentQuestions(tableData)
                          //     setAssignmentName({
                          //       subjectName: tableData.katbookSubjectName,
                          //       ExamName : tableData.examName,
                          //       examDate : tableData.examDate,

                          //     })
                          //   }}
                          className="py-3 StudentAssignment__tableElements"
                          style={{
                            border: "1px solid #E6ECF1",
                          }}
                        >
                          <td className="p-2 StudentAssignment__tableStart">
                            {index + 1}
                          </td>
                          <td className="p-2 StudentAssignment__tableStart">
                            {tableData.katbookSubjectName}
                          </td>
                          <td className="p-2 StudentAssignment__tableStart">
                            {tableData.ageGroup}
                          </td>
                          <td className="p-2">{tableData.type === "mockup" ? "Mockup Exam" : "Final Exam"}</td>
                          <td className="p-2">{tableData.examName}</td>
                          <td className="p-2">{tableData.totalMarks}</td>
                          <td className="p-2">
                            {tableData.examDate === null ||
                            tableData.examDate === undefined
                              ? "Not Assigned"
                              : moment(tableData.examDate).format(
                                  "DD/MMM/YYYY"
                                )}
                          </td>
                          <td className="p-2 StudentAssignment__tableEnd">
                            {tableData.examStartTime === null ||
                            tableData.examStartTime === undefined
                              ? "Not Assigned"
                              : `${moment(
                                  tableData.examDate +
                                    " " +
                                    tableData.examStartTime
                                ).format("hh:mm A")} - ${moment(
                                  tableData.examDate +
                                    " " +
                                    tableData.examEndTime
                                ).format("hh:mm A")}`}
                          </td>
                          <td
                            className="p-2 StudentAssignment__tableStart"
                            style={{
                              backgroundColor: tableData.posted
                                ? "green"
                                : "red",
                              color: "#fff",
                            }}
                          >
                            {tableData.posted === true ? "True" : "False"}
                          </td>
                          <td>
                            <AiOutlineEdit
                              onClick={() => {
                                updateFuc(tableData);
                              }}
                              className="borderCircleEdit"
                              size="40"
                            />
                            &nbsp;&nbsp;
                            {/* <RiDeleteBin6Line
                          className="borderCircleDelete"
                          size="40"
                        /> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="10">Exam not available</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </>
          )}
          {updateViewEnable && (
            <>
              <KHeader title="Update Exam " containerClassName={``} />
              <div className="container-fluid px-5 py-3">
                {/* Create Assignment Component Starts With Responsive */}
                <div className="d-flex flex-column flex-md-row col-md-12">
                  <div className={"col-md-3"}>
                    <KDropdown
                      containerClassName="w-100"
                      label="Select Course"
                      isRequired={true}
                      placeholder="Select Course"
                      value={singleSubject}
                      options={subjectsData}
                      onChange={(e) => {
                        setsingleSubject(e);
                      }}
                    />
                  </div>
                  <div className={"col-md-3"}>
                <KDropdown
                  containerClassName="w-100"
                  label="Select Type"
                  isRequired={true}
                  placeholder="Select Type"
                  value={singleExamType}
                  options={ExamType}
                  onChange={(e) => {
                    setsingleExamType(e);
                  }}
                />
              </div>
                  <div className={"col-md-3"}>
                    <KInput
                      label="Exam Name"
                      isRequired={true}
                      placeholder="Enter Exam Name"
                      width={"300px"}
                      containerClassName=""
                      onChange={setAsstTitle}
                      value={AsstTitle}
                    />
                  </div>
                  <div className={"col-md-3"}>
                    <KInput
                      label="Total Marks"
                      isRequired={true}
                      placeholder="Enter Total Marks"
                      containerClassName={``}
                      labelClassName={"width-maxContent"}
                      inputClassName="w-100"
                      type="number"
                      value={AsstMarks}
                      onChange={setAsstMarks}
                      toast={toast}
                      Warning={Warning}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row marginTop-20 col-md-12">
                  <div className={"col-md-3"}>
                    <KInput
                      label="Exam Date"
                      containerClassName=""
                      isRequired={true}
                      value={AsstDate}
                      onChange={setAsstDate}
                      // onChange={(e) => {
                      //   getAllQuestionsFuc(e)
                      // }}
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      // max="2030-12-31"
                    />
                  </div>
                  <div className={"col-md-3"}>
                    <KInput
                      label="Exam Start Time"
                      isRequired={true}
                      placeholder=""
                      containerClassName=""
                      onChange={(e) => {
                        setExamSTime(e);
                      }}
                      value={ExamSTime}
                      labelClassName={"width-maxContent"}
                      inputClassName="w-100"
                      type="time"
                    />
                  </div>
                  <div className={"col-md-3"}>
                    <KInput
                      label="Exam End Time"
                      isRequired={true}
                      placeholder=""
                      containerClassName=""
                      onChange={(e) => {
                        setExamETime(e);
                      }}
                      value={ExamETime}
                      labelClassName={"width-maxContent"}
                      inputClassName="w-100"
                      type="time"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row marginTop-20 col-md-12">
                  <div className={"col-md-9"}>
                    <KRequiredLabel
                      isRequired={false}
                      title="Add Exam Instruction"
                      className="h6 mt-3"
                      style={{
                        display: "inline-block",
                        alignSelf: "flex-end",
                      }}
                    />
                    <KEditor
                      height={isBigScreen ? 400 : 500}
                      value={InstructionsTxt}
                      handleEditorChange={(content) => {
                        onChangeInstrusFuc(content);
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`w-100 pt-3 pb-5 cursor-pointer marginTop-20 marginBottom-20`}
                >
                  
                  <KButton
                    onClick={() => {
                      UpdateAssignmentFunc();
                    }}
                    value="UPDATE"
                    className={`mb-2 marginLeft-10 float-right px-3 py-2 ${
                      isBigScreen ? "w-auto" : "w-100"
                    }`}
                    isDisabled={updateBtn}
                  />

                  <KButton
                    onClick={() => {
                      EmptyAssigtCreateData();
                    }}
                    value="CANCEL"
                    className={`mb-2  float-right px-3 py-2 ${
                      isBigScreen ? "w-auto" : "w-100"
                    }`}
                    isDisabled={false}
                    btnColor="red"
                  />

                </div>

                <br />
              </div>
            </>
          )}
        </div>
      </div>

      <Footer
      // openForm={openForm}
      // shoq={shoq}
      // closeForm={closeForm}
      // KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default AssignmentList;
